<template>
  <div v-if="!!data">
    <p v-if="$i18n.locale === 'CH'">
      {{ data.companyDescriptionCh }}
    </p>
    <p v-if="$i18n.locale === 'EN'">
      {{ data.companyDescriptionEn }}
    </p>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "IntroductionBar",
  data() {
    return {
      data: null,
    };
  },
  mounted() {
    axios
      .get(this.$webDomain + "/estateInit/EstateBasic")
      .then((response) => (this.data = response.data.data[0]));
  },
};
</script>

<style scoped>
p {
  padding-top: 10px;
  padding-right: 25px;
  padding-left: 25px;
  font-size: 20px;
}
</style>
