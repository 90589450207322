import axios from "axios";
import globalProperties from "../globalProperties";

const webDomain = globalProperties.webDomain;

const API_URL = webDomain + "/auth/";

class AuthService {
  login(user) {
    return axios
      .post(API_URL + "login", {
        email: user.email,
        password: user.password,
      })
      .then((response) => {
        if (response.data) {
          // added JSON.stringify to make token value readable for JSON
          localStorage.setItem(
            "token",
            JSON.stringify(response.data.data.token)
          );
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("token");
  }
}

export default new AuthService();
