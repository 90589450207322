<template>
  <div class="card">
    <div class="card-pic">
      <img
        :src="$webDomain + '/' + card.mainImageType + '/' + card.mainImage"
        class="card-img-top"
        alt="not found"
      />
    </div>
    <div class="card-body">
      <h5 class="card-title">{{ card.firstName }} {{ card.lastName }}</h5>
      <p class="card-text">{{ card.jobTitle }}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: "Representative",
  props: {
    card: Object,
  },
};
</script>
<style scoped>
.card {
  width: 225px;
}

.card-pic {
  overflow: hidden;
}

.card-pic img {
  height: 225px;
  object-fit: cover;
}
</style>
