<template>
  <div class="container my-5">
    <div class="mb-3 text-center">
      <h3 class="d-inline me-3 mb-3 fw-bold">Listing</h3>
      <BootstrapIcon
        class="add-btn primary"
        icon="plus-circle"
        @click="showModal"
      />
    </div>

    <div class="d-flex flex-row-reverse">
      <div class="mb-5 item-amount-select">
        <p class="d-inline me-3">#Item to show</p>
        <select
          class="form-select d-inline"
          aria-label="Default select"
          v-model="itemToShow"
          @change="getData"
        >
          <option value="3">3</option>
          <option value="5">5</option>
          <option value="10">10</option>
        </select>
      </div>
    </div>

    <table class="table table-hover table-bordered align-middle admin-table">
      <caption>
        Listings
      </caption>
      <thead class="table-light">
        <tr>
          <th scope="col" class="admin-table-preview">Preview</th>
          <th scope="col">MLS#</th>
          <th scope="col">Price $</th>
          <th scope="col">Intersection</th>
          <th scope="col">Address</th>
          <th scope="col">Option</th>
        </tr>
      </thead>
      <tbody>
        <tr :key="list._id" v-for="list in data">
          <AdminListTable v-if="list" :list="list" />
        </tr>
      </tbody>
    </table>

    <!-- Page Nav -->
    <nav aria-label="Page" class="text-center">
      <ul class="pagination">
        <li class="page-item">
          <a class="page-link" @click="prevPage" aria-label="Previous">
            <span aria-hidden="true">&laquo;</span>
          </a>
        </li>
        <li>
          <p>Current Page: {{ page + 1 }}</p>
        </li>
        <li class="page-item">
          <a class="page-link" @click="nextPage" aria-label="Next">
            <span aria-hidden="true">&raquo;</span>
          </a>
        </li>
      </ul>
    </nav>
    <hr />
    <ModalListing v-if="displayModal" @close-modal-event="hideModal" />
  </div>
</template>

<script>
import axios from "axios";
import ModalListing from "./ModalListing.vue";
import AdminListTable from "./AdminListTable.vue";
export default {
  name: "AdminListing",
  components: {
    ModalListing,
    AdminListTable,
  },
  data() {
    return {
      data: null,
      displayModal: false,
      page: 0,
      itemToShow: 5,
    };
  },
  computed: {
    itemAmount() {
      return this.itemToShow;
    },
  },
  methods: {
    showModal() {
      this.displayModal = true;
    },
    hideModal() {
      this.displayModal = false;
    },
    nextPage() {
      if (this.data.length === this.itemAmount) this.page++;
      this.getData();
    },
    prevPage() {
      if (this.page > 0) {
        this.page--;
        this.getData();
      }
    },
    getData() {
      axios
        .get(
          this.$webDomain +
            "/estateInit/EstateListing?size=" +
            this.itemAmount +
            "&page=" +
            this.page
        )
        .then((response) => {
          this.data = response.data.data;
        });
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style scoped>
nav ul {
  display: inline-block;
}
nav li {
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}
.page-link {
  cursor: pointer;
}

.item-amount-select select {
  width: 100px;
}
</style>
