<template>
  <div class="mb-5">
    <Carousel :settings="settings" :breakpoints="breakpoints" :autoplay="3000">
      <slide v-for="(adv, index) in data" :key="index">
        <Adv :adv="adv" />
      </slide>

      <template #addons>
        <Navigation />
      </template>
    </Carousel>
  </div>
</template>
<script>
import Adv from "./Advertisement.vue";

import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";

export default {
  name: "advedrtisements",
  props: {
    // data is not returning an array, but an object
    data: Object,
  },
  components: {
    Adv,
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      // carousel settings
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
        wrapAround: "true",
        transition: 400,
      },
      // breakpoints are mobile first
      // any settings not specified will fallback to the carousel settings
      breakpoints: {
        490: {
          itemsToShow: 2,
        },
        // 700px and up
        992: {
          itemsToShow: 3,
        },
        // 1024 and up
        1200: {
          itemsToShow: 4,
        },
        // 1400: {
        //   itemsToShow: 5,
        // },
      },
    };
  },
};
</script>
