<template>
  <div class="footer-contact pt-4 pb-5" v-if="!!data">
    <h1 class="text-center mb-2">
      {{ $t("homePage.footer.title") }}
    </h1>

    <div class="bottom-line"></div>
    <div class="row row-cols-1 row-cols-md-2 mx-0 justify-content-center">
      <div class="col text-center">
        <h3 v-if="$i18n.locale === 'CH'">
          {{ data.companyNameCh }}
        </h3>
        <h3 v-if="$i18n.locale === 'EN'">
          {{ data.companyNameEn }}
        </h3>
        <p>{{ data.street }}</p>
        <p v-if="data.unit">{{ "Unit " + data.unit }}</p>
        <p>{{ data.city }}</p>
        <p>{{ data.province }} , {{ data.postcode }}</p>
        <p>
          <font-awesome-icon :icon="['fas', 'phone']" /> {{ data.phoneNumber }}
        </p>
        <p><font-awesome-icon :icon="['fas', 'fax']" /> {{ data.faxNumber }}</p>
        <p>
          <font-awesome-icon :icon="['fas', 'envelope']" />
          {{ data.emailAddress }}
        </p>
      </div>
      <div class="col map mb-4">
        <Map :data="data" />
      </div>
    </div>
  </div>
  <div class="footer-bottom-bar" v-if="!!data">
    <p class="copyright">&copy; {{ data.copyRight }}</p>
  </div>
</template>
<script>
import Map from "./Map.vue";
import axios from "axios";
export default {
  name: "Footer",
  components: {
    Map,
  },
  data() {
    return {
      data: null,
    };
  },
  mounted() {
    axios
      .get(this.$webDomain + "/estateInit/EstateBasic")
      .then((response) => (this.data = response.data.data[0]));
  },
};
</script>
<style scoped>
.map {
  width: 600px;
}

h3 {
  margin-bottom: 20px;
}

p {
  font-size: 20px;
  margin-bottom: 0ex;
  padding-bottom: 15px;
}

h1 {
  font-size: 38px;
  padding-top: 50px;
}

.copyright {
  text-align: center;
  font-size: 15px;
  color: #eef3f5;
  padding-top: 35px;
  padding-left: 0px;
}
.row {
  width: 100%;
}
.col {
  padding: 20px;
}
</style>
