<template>
  <div
    class="modal"
    :id="'a' + message._id"
    tabindex="-1"
    aria-labelledby="emailModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header bg-light">
          <h5 class="modal-title" id="emailModalLabel">
            From: {{ message.email }}
          </h5>

          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        <div class="modal-body">
          <div class="text-start p-3">
            <h5>Name: {{ message.name }}</h5>
            <h5>Phone: {{ message.phone }}</h5>
            <h5>Date/Time: {{ receiveTime }}</h5>
          </div>
          <div class="card mb-3 mx-3">
            <div class="card-body">
              <div>
                {{ message.messageContent }}
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer bg-light">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["message", "receiveTime"],
};
</script>
