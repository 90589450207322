import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "./views/Home.vue";
import About from "./views/About.vue";
import Admin from "./views/Admin.vue";
import AdminBasic1 from "./components/Admin/AdminBasic1.vue";
import AdminTopImage from "./components/Admin/AdminTopImage.vue";
import AdminListing from "./components/Admin/AdminListing.vue";
import AdminEvent from "./components/Admin/AdminEvent.vue";
import AdminJoin from "./components/Admin/AdminJoin.vue";
import AdminAdv from "./components/Admin/AdminAdvertise.vue";
import AdminEmail from "./components/Admin/AdminEmail.vue";
import AdminPassword from "./components/Admin/AdminPassword.vue";
import Login from "./views/Login.vue";
import LoginForgotPassword from "./components/Login/LoginForgotPassword.vue";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/listing/:id",
    name: "details",
    component: About,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/forgotPassword",
    name: "forgotPassword",
    component: LoginForgotPassword,
  },
  {
    path: "/admin",
    name: "admin",
    component: Admin,
    children: [
      {
        path: "",
        component: AdminBasic1,
      },
      {
        path: "topimage",
        component: AdminTopImage,
      },
      {
        path: "listing",
        component: AdminListing,
      },
      {
        path: "event",
        component: AdminEvent,
      },
      {
        path: "join",
        component: AdminJoin,
      },
      {
        path: "advertise",
        component: AdminAdv,
      },
      {
        path: "email",
        component: AdminEmail,
      },
      {
        path: "password",
        component: AdminPassword,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      };
    }
  },
});

export default router;
