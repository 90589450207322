<template>
  <div>
    <Carousel :settings="settings" :breakpoints="breakpoints" :autoplay="3000">
      <slide v-for="cardInfo in data" :key="cardInfo._id">
        <Representative :card="cardInfo" />
      </slide>
    </Carousel>
  </div>
</template>

<script>
import axios from "axios";
import Representative from "./Representative.vue";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide } from "vue3-carousel";

export default {
  name: "ImgAnimation",
  components: {
    Representative,
    Carousel,
    Slide,
  },
  data() {
    return {
      data: null,
      // carousel settings
      settings: {
        itemsToShow: 1,
        snapAlign: "start",
        wrapAround: "true",
        transition: 800,
      },
      // breakpoints are mobile first
      // any settings not specified will fallback to the carousel settings
      breakpoints: {
        768: {
          itemsToShow: 2,
        },
        // 700px and up
        992: {
          itemsToShow: 3,
          snapAlign: "start",
        },
        // 1024 and up
        1200: {
          itemsToShow: 4,
          snapAlign: "start",
        },
      },
    };
  },
  mounted() {
    axios
      .get(this.$webDomain + "/estateInit/EstateJoin")
      .then((response) => (this.data = response.data.data));
  },
};
</script>

<style>
/* Attention! Not Scoped! */
button:hover {
  opacity: 0.8;
}

button.carousel__prev {
  left: 10px;
  background-color: #cb011b;
}

button.carousel__next {
  right: 10px;
  background-color: #cb011b;
}
</style>
