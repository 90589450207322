<template>
  <div class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header bg-light">
          <h5 class="modal-title">Edit Room Infomation</h5>
          <button type="button" class="btn-close" @click="closeModal"></button>
        </div>
        <div class="modal-body">
          <form>
            <div class="form-group">
              <div class="row">
                <div class="col">
                  <label for="roomType" class="form-label">Room Type</label>
                  <select
                    class="form-select"
                    id="roomType"
                    v-model="roomType"
                    @change="update('roomType', roomType)"
                  >
                    <option disabled value="">Room type</option>
                    <option value="1">Livingroom</option>
                    <option value="2">Bedroom</option>
                    <option value="3">Kitchen</option>
                    <option value="4">Washroom</option>
                    <option value="5">Balcony</option>
                  </select>
                  <label for="roomWidth" class="form-label">Width</label>
                  <input
                    type="text"
                    class="form-control"
                    id="roomWidth"
                    v-model="roomWidth"
                    @change="update('roomWidth', roomWidth)"
                  />
                  <label for="roomHeight" class="form-label">Height</label>
                  <input
                    type="text"
                    class="form-control"
                    id="roomHeight"
                    v-model="roomHeight"
                    @change="update('roomHeight', roomHeight)"
                  />
                </div>
                <div class="col">
                  <label for="roomLocationType" class="form-label"
                    >Location Type</label
                  >
                  <select
                    class="form-select"
                    id="roomLocationType"
                    v-model="roomLocationType"
                    @change="update('roomLocationType', roomLocationType)"
                  >
                    <option disabled value="">Location type</option>
                    <option value="1">Ground Floor</option>
                    <option value="2">Second Floor</option>
                    <option value="3">Basement</option>
                  </select>

                  <label for="roomUnitType" class="form-label">Unit Type</label>
                  <select
                    class="form-select"
                    id="roomUnitType"
                    v-model="roomUnitType"
                    @change="update('roomUnitType', roomUnitType)"
                  >
                    <option disabled value="">Unit type</option>
                    <option value="1">meter</option>
                    <option value="2">inch</option>
                    <option value="3">feet</option>
                  </select>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer bg-light">
          <button type="button" class="btn btn-primary" @click="saveChanges">
            Save
          </button>
          <button type="button" class="btn btn-secondary" @click="closeModal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import authHeader from "../../services/authHeader";
import axios from "axios";
export default {
  name: "ModalEvent",
  props: ["listId", "rooms"],
  emits: ["reopen-modal-room", "close-modal-event"],
  data() {
    return {
      roomType: this.rooms.roomType,
      roomWidth: this.rooms.roomWidth,
      roomHeight: this.rooms.roomHeight,
      roomLocationType: this.rooms.roomLocationType,
      roomUnitType: this.rooms.roomUnitType,
      id: this.rooms._id,
      obj: {},
    };
  },
  methods: {
    closeModal() {
      this.$emit("close-modal-event");
    },
    saveChanges() {
      axios
        .put(
          this.$webDomain + "/estate/EstateListingList",
          {
            itemId: this.listId,
            cellId: this.id,
            subItemListField: "roomList",
            roomType: this.roomType,
            roomWidth: this.roomWidth,
            roomHeight: this.roomHeight,
            roomLocationType: this.roomLocationType,
            roomUnitType: this.roomUnitType,
          },
          { headers: authHeader() }
        )
        .then((response) => {
          console.log(response);
          this.reopenEdit();
        })
        .catch(function(error) {
          console.log(error);
        });

      this.closeModal();
    },
    reopenEdit() {
      this.$emit("reopen-modal-room");
    },
    update(key, value) {
      if (
        key === "roomType" ||
        key === "roomWidth" ||
        key === "roomHeight" ||
        key === "roomLocationType" ||
        key === "roomUnitType"
      ) {
        this.obj[key] = value;
      }
    },
  },
};
</script>

<style scoped>
/* Override default value of 'none' */
.modal {
  display: block;
}

.form-label {
  margin-top: 0.5rem;
}
</style>
