<template>
  <div class="modal" tabindex="-1">
    <div
      class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable"
    >
      <div class="modal-content">
        <div class="modal-header bg-light">
          <h5 class="modal-title">New Listing Details</h5>
          <button type="button" class="btn-close" @click="closeModal"></button>
        </div>
        <div class="modal-body container">
          <div class="row">
            <!-- General Information Card -->
            <div class="inner-container col-md-4 p-3">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">General Information</h5>
                  <hr />
                  <!-- MLS -->
                  <div class="form-floating mb-3">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="MLS® No"
                      aria-label="MLS"
                      v-model="mls"
                      @change="update('mlsNumber', mls)"
                    />
                    <label>MLS® No</label>
                  </div>
                  <!-- Price -->
                  <div class="form-floating mb-3">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Price$"
                      aria-label="Amount"
                      v-model="price"
                      @change="update('price', price)"
                    />
                    <label>Price</label>
                  </div>
                </div>
              </div>
              <!-- Location Card -->
              <div class="card mt-3">
                <div class="card-body">
                  <h5 class="card-title">Location</h5>
                  <hr />
                  <!-- Intersection -->
                  <div class="form-floating mb-3">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Intersection"
                      aria-label="intersection"
                      v-model="intersection"
                      @change="update('intersection', intersection)"
                    />
                    <label>Intersection</label>
                  </div>
                  <!-- Community -->
                  <div class="form-floating mb-3">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Community"
                      aria-label="community"
                      v-model="community"
                      @change="update('community', community)"
                    />
                    <label>Community</label>
                  </div>
                  <!-- Unit -->
                  <div class="form-floating mb-3">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Unit"
                      aria-label="unit"
                      v-model="unit"
                      @change="update('unit', unit)"
                    />
                    <label>Unit</label>
                  </div>
                  <!-- Street -->
                  <div class="form-floating mb-3">
                    <input
                      type="text"
                      class="form-control"
                      id="street"
                      placeholder="Street"
                      aria-label="street"
                      v-model="street"
                      @change="update('street', street)"
                    />
                    <label for="street">Street</label>
                  </div>
                  <!-- City -->
                  <div class="form-floating mb-3">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="City"
                      aria-label="city"
                      v-model="city"
                      @change="update('city', city)"
                    />
                    <label>City</label>
                  </div>
                  <!-- Province -->
                  <div class="form-floating mb-3">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Province"
                      aria-label="province"
                      v-model="province"
                      @change="update('province', province)"
                    />
                    <label>Province</label>
                  </div>
                  <!-- Country -->
                  <div class="form-floating mb-3">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Country"
                      aria-label="country"
                      v-model="country"
                      @change="update('country', country)"
                    />
                    <label>Country</label>
                  </div>
                  <!-- Post Code -->
                  <div class="form-floating mb-3">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Postcode"
                      aria-label="postcode"
                      v-model="postcode"
                      @change="update('postcode', postcode)"
                    />
                    <label>Post Code</label>
                  </div>
                </div>
              </div>
            </div>

            <!-- Property Description Card -->
            <div class="inner-container col-md-4 p-3">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Property Information</h5>
                  <hr />
                  <!-- dimension -->
                  <div class="row">
                    <!-- Width -->
                    <div class="col">
                      <div class="form-floating mb-3">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Land Width"
                          aria-label="landwidth"
                          v-model="width"
                          @change="update('landWidth', width)"
                        />
                        <label>Width</label>
                      </div>
                    </div>

                    <!-- Height -->
                    <div class="col">
                      <div class="form-floating mb-3">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Land Heigth"
                          aria-label="landheight"
                          v-model="height"
                          @change="update('landHeight', height)"
                        />
                        <label>Height</label>
                      </div>
                    </div>
                  </div>
                  <!-- Property Type -->
                  <div class="col">
                    <div class="form-floating mb-3">
                      <select
                        class="form-select"
                        v-model="propertyType"
                        @change="update('propertyType', propertyType)"
                      >
                        <option disabled value="">Property Type</option>
                        <option value="1">Detached</option>
                        <option value="2">Semi-Detached</option>
                        <option value="3">Att/Row/Twnhouse</option>
                        <option value="4">Condo Apt</option>
                      </select>
                      <label>Property Type</label>
                    </div>
                  </div>
                  <!-- Property Style -->
                  <div class="col">
                    <div class="form-floating mb-3">
                      <select
                        class="form-select"
                        v-model="propertyStyle"
                        @change="update('propertyStyle', propertyStyle)"
                      >
                        <option disabled value="">Property Style</option>
                        <option value="1">2-Storey</option>
                        <option value="2">3-Storey</option>
                        <option value="3">Apartment</option>
                        <option value="4">Bungalow</option>
                      </select>
                      <label>Property Style</label>
                    </div>
                  </div>
                  <!-- Exterior Finish -->
                  <div class="col">
                    <div class="form-floating mb-3">
                      <select
                        class="form-select"
                        v-model="exterior"
                        @change="update('exteriorStyle', exterior)"
                      >
                        <option disabled value="">Exterior Finish</option>
                        <option value="1">Stone</option>
                        <option value="2">Brick</option>
                        <option value="3">Fiber Cement</option>
                        <option value="4">Wood</option>
                      </select>
                      <label>Exterior Finish</label>
                    </div>
                  </div>
                  <!-- Basement -->
                  <div class="col">
                    <div class="form-floating mb-3">
                      <select
                        class="form-select"
                        v-model="basement"
                        @change="update('basementStyle', basement)"
                      >
                        <option disabled value="">Basement Style</option>
                        <option value="1">Finished</option>
                        <option value="2">Unfinished</option>
                        <option value="3">None</option>
                      </select>
                      <label>Basement</label>
                    </div>
                  </div>

                  <!-- Rooms -->
                  <div class="row">
                    <!-- Bedroom -->
                    <div class="col">
                      <div class="form-floating mb-3">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="#Bedrooms"
                          aria-label="numberOfBedrooms"
                          v-model="bedroom"
                          @change="update('bedroomsNumber1', bedroom)"
                        />
                        <label>Bedroom</label>
                      </div>
                    </div>
                    <!-- Bathroom -->
                    <div class="col">
                      <div class="form-floating mb-3">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="#Bathrooms"
                          aria-label="numberOfBathrooms"
                          v-model="bathroom"
                          @change="update('bathroomNumber', bathroom)"
                        />
                        <label>Bathroom</label>
                      </div>
                    </div>
                  </div>
                  <!-- Kitchen & Parking -->
                  <div class="row">
                    <!-- Kitchen -->
                    <div class="col">
                      <div class="form-floating mb-3">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="#Kitchens"
                          aria-label="numberOfKitchens"
                          v-model="kitchen"
                          @change="update('kitchenNumber', kitchen)"
                        />
                        <label>Kitchen</label>
                      </div>
                    </div>
                    <!-- Parking -->
                    <div class="col">
                      <div class="form-floating mb-3">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="#Parkings"
                          aria-label="numberOfParkings"
                          v-model="parking"
                          @change="update('parkingNumber', parking)"
                        />
                        <label>Parking</label>
                      </div>
                    </div>
                  </div>
                  <!-- Garage -->
                  <div class="col">
                    <div class="form-floating mb-3">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="#Garage"
                        aria-label="numberOfGarages"
                        v-model="garage"
                        @change="update('garageNumber', garage)"
                      />
                      <label>Garage</label>
                    </div>
                  </div>
                  <!-- Garage Type -->
                  <div class="col">
                    <div class="form-floating mb-3">
                      <select
                        class="form-select"
                        v-model="garageType"
                        @change="update('garageType', garageType)"
                      >
                        <option disabled value="">Garage Type</option>
                        <option value="1">Built-In</option>
                        <option value="2">Attached</option>
                        <option value="3">Underground</option>
                        <option value="4">None</option>
                      </select>
                      <label>Garage Type</label>
                    </div>
                  </div>
                  <!-- Central AC -->
                  <div class="col">
                    <div class="form-floating mb-3">
                      <select
                        class="form-select"
                        v-model="centralAC"
                        @change="update('centralAC', centralAC)"
                      >
                        <option disabled value="">Central AC</option>
                        <option value="1">N</option>
                        <option value="2">Y</option>
                      </select>
                      <label>Central AC</label>
                    </div>
                  </div>

                  <!-- Heat -->
                  <div class="row">
                    <!-- Heat Source -->
                    <div class="col">
                      <div class="form-floating mb-3">
                        <select
                          class="form-select"
                          v-model="heatSource"
                          @change="update('heatSource', heatSource)"
                        >
                          <option disabled value="">Heat Source</option>
                          <option value="1">Gas</option>
                          <option value="2">Oil</option>
                          <option value="3">Water</option>
                          <option value="4">Electrical</option>
                        </select>
                        <label>Heat Source</label>
                      </div>
                    </div>
                    <!-- Heat Type -->
                    <div class="col">
                      <div class="form-floating mb-3">
                        <select
                          class="form-select"
                          v-model="heatType"
                          @change="update('heatType', heatType)"
                        >
                          <option disabled value="">Heat Type</option>
                          <option value="1">Forced Air</option>
                          <option value="2">Heat Pump</option>
                          <option value="3">Boiler</option>
                        </select>
                        <label>Heat Type</label>
                      </div>
                    </div>
                  </div>

                  <!-- Device -->
                  <div class="row">
                    <!-- Fire Place -->
                    <div class="col">
                      <div class="form-floating mb-3">
                        <select
                          class="form-select"
                          v-model="firePlace"
                          @change="update('firePlace', firePlace)"
                        >
                          <option disabled value="">Fire Place</option>

                          <option value="1">N</option>
                          <option value="2">Y</option>
                        </select>
                        <label>Fire Place</label>
                      </div>
                    </div>
                    <!-- Pool -->
                    <div class="col">
                      <div class="form-floating mb-3">
                        <select
                          class="form-select"
                          v-model="pool"
                          @change="update('poolType', pool)"
                        >
                          <option disabled value="">Pool</option>
                          <option value="1">N</option>
                          <option value="2">Y</option>
                        </select>
                        <label>Pool</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Description Card -->
            <div class="inner-container col-md-4 p-3">
              <div class="card h-100">
                <div class="card-body">
                  <h5 class="card-title">Description</h5>
                  <hr />
                  <!-- In English -->
                  <h6>English</h6>
                  <div class="form-floating mb-4">
                    <textarea
                      class="form-control bg-light"
                      placeholder="Content"
                      v-model="descriptionEn"
                      @change="update('descriptionEn', descriptionEn)"
                    ></textarea>
                    <label>Description in English</label>
                  </div>
                  <!-- In Chinese -->
                  <h6>Chinese</h6>
                  <div class="form-floating">
                    <textarea
                      class="form-control bg-light"
                      placeholder="Content"
                      v-model="descriptionCh"
                      @change="update('descriptionCh', descriptionCh)"
                    ></textarea>
                    <label>Description in Chinese</label>
                  </div>
                </div>
              </div>
            </div>

            <!-- Preview Image Section -->
            <div class="inner-container p-3">
              <div class="card">
                <div class="card-body">
                  <h5 class="d-inline me-2 card-title">Preview Image</h5>
                  <BootstrapIcon
                    class="add-btn primary mb-0"
                    icon="plus-circle"
                    @click="showModal"
                  />

                  <table class="table table-hover mt-3">
                    <thead>
                      <tr>
                        <th scope="col">Uploaded Preview Image</th>
                      </tr>
                    </thead>
                    <tbody class="text-center">
                      <tr>
                        <div id="preview">
                          <img v-if="imageUrl" :src="imageUrl" />
                        </div>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer bg-light">
          <button type="button" class="btn btn-primary" @click="saveChanges">
            Upload
          </button>
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
            @click="closeModal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
  <ModalListingPreviewImage
    v-if="displayModal"
    @close-modal-event="hideModal"
    :id="id"
  />
</template>

<script>
import axios from "axios";
import authHeader from "../../services/authHeader";
import ModalListingPreviewImage from "./ModalListingPreviewImage.vue";

export default {
  name: "ModalListing",
  emits: ["close-modal-event"],
  components: {
    ModalListingPreviewImage,
  },
  data() {
    return {
      // display control
      displayModal: false,
      displayModalImage: false,
      displayModalRoom: false,
      displayModalPreviewImg: false,
      // Room List
      roomList: [],
      //listing image
      listingImageList: [],
      // preview image
      imageUrl: null,
      // input properties
      mls: "",
      price: "",
      intersection: "",
      community: "",
      unit: "",
      street: "",
      city: "",
      province: "",
      country: "",
      postcode: "",
      propertyType: "",
      propertyStyle: "",
      width: "",
      height: "",
      basement: "",
      exterior: "",
      bedroom: "",
      bathroom: "",
      kitchen: "",
      garageType: "",
      garage: "",
      parking: "",
      firePlace: "",
      centralAC: "",
      heatSource: "",
      heatType: "",
      pool: "",
      descriptionEn: "",
      descriptionCh: "",
      // upload object
      obj: {},
      // id
      id: "",
    };
  },
  methods: {
    closeModal() {
      this.$emit("close-modal-event");
    },
    saveChanges() {
      axios
        .post(this.$webDomain + "/estate/EstateListing", this.obj, {
          headers: authHeader(),
        })
        .then((response) => {
          this.id = response.data.data._id;
          window.location.reload();
        })
        .catch(function(error) {
          console.log(error);
        });
      this.$emit("close-modal-event");
    },
    showModal() {
      this.displayModal = true;
    },
    hideModal() {
      this.displayModal = false;
    },
    // Show & Hide Modal Image
    showModalImage() {
      this.displayModalImage = true;
    },
    hideModalImage() {
      this.displayModalImage = false;
    },
    // Show & Hide Modal Room
    showModalRoom() {
      this.displayModalRoom = true;
    },
    hideModalRoom() {
      this.displayModalRoom = false;
    },
    // Show & Hide Modal Preview Image
    showModalPreviewImage() {
      this.displayModalPreviewImg = true;
    },
    hideModalPreviewImage() {
      this.displayModalPreviewImg = false;
    },
    update(key, value) {
      if (
        key === "mlsNumber" ||
        key === "intersection" ||
        key === "community" ||
        key === "unit" ||
        key === "street" ||
        key === "city" ||
        key === "province" ||
        key === "country" ||
        key === "postcode" ||
        key === "descriptionEn" ||
        key === "descriptionCh" ||
        key === "listingImageList"
      ) {
        this.obj[key] = value;
      } else {
        this.obj[key] = parseFloat(value);
      }
    },
  },
};
</script>

<style scoped>
.modal {
  display: block;
}

.modal-fullscreen {
  width: 99vw;
}

textarea {
  height: 260px !important;
  background-color: #f6f6f6;
}

#preview img {
  width: 200px;
}
</style>
