<template>
  <div class="container py-5">
    <div class="title mb-2">
      <div class="d-inline-block">
        <div class="title fs-5">{{ $t("about.room.rooms") }}</div>
        <div class="bottom-line"></div>
      </div>
    </div>
    <table class="table table-striped">
      <thead>
        <tr>
          <th>{{ $t("about.room.roomType") }}</th>
          <th>{{ $t("about.room.size") }}</th>
          <th>{{ $t("about.room.roomUnitType") }}</th>
          <th>{{ $t("about.room.roomLocationType") }}</th>
        </tr>
      </thead>
      <tbody>
        <Room v-for="room in roomList" :key="room.index" :room="room" />
      </tbody>
    </table>
  </div>
</template>
<script>
import Room from "./Room.vue";
export default {
  name: "Rooms",
  props: {
    roomList: Array,
  },
  components: {
    Room,
  },
};
</script>
<style scoped>
.title {
  color: #343a40;
  font-weight: bold;
  text-align: left;
}

.bottom-line {
  height: 4px;
  width: 2.9rem;
  background-color: #cb011b;
  display: block;
  margin: 0.7rem auto 1rem auto;
}
</style>
