<template>
  <td>
    <img
      class="preview-img"
      :src="$webDomain + '/' + adv.mainImageType + '/' + adv.mainImage"
      alt="not found"
    />
  </td>
  <!-- <td>{{ adv.titleEn }}</td>
  <td>{{ adv.titleCh }}</td>
  <td>{{ adv.locationType }}</td> -->
  <td>{{ adv.link }}</td>
  <td>
    <BootstrapIcon
      class="listTableIcon"
      icon="pencil-square"
      @click="showEdit()"
    />
    <BootstrapIcon
      class="listTableIcon  delete"
      icon="trash"
      @click="deleteItem(adv._id)"
    />
  </td>
  <ModalAdvEdit
    :adv="adv"
    v-if="displayEdit"
    @close-modal-event="hideEdit"
  ></ModalAdvEdit>
</template>
<script>
import axios from "axios";
import authHeader from "../../services/authHeader";
import ModalAdvEdit from "./ModalAdvEdit.vue";
export default {
  name: "AdminAdvTable",
  props: {
    adv: Object,
  },
  components: {
    ModalAdvEdit,
  },
  data() {
    return {
      displayEdit: false,
    };
  },
  methods: {
    deleteItem(id) {
      axios
        .delete(this.$webDomain + "/estate/EstatePoster", {
          headers: authHeader(),
          data: {
            itemId: id,
          },
        })
        .then(function(response) {
          console.log(response);
          window.location.reload();
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    showEdit() {
      this.displayEdit = true;
      console.log(this.adv);
    },
    hideEdit() {
      this.displayEdit = false;
    },
  },
};
</script>
