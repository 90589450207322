<template>
  <!-- original api-key="AIzaSyAVlIwpW-3TG431Lu1IvfbTTLmT5wA__-w" -->
  <GoogleMap
    api-key="AIzaSyBg9EzjYZ6qUXCcNpF14D2I9EHWHNkI_hg"
    style="width: 100%; height: 300px"
    :center="center"
    :zoom="15"
  >
    <Marker :options="{ position: center }" />
  </GoogleMap>
</template>
<script>
import { defineComponent } from "vue";
import { GoogleMap, Marker } from "vue3-google-map";

export default defineComponent({
  props: { data: Object },
  components: { GoogleMap, Marker },
  setup(props) {
    const center = { lat: props.data.latitude, lng: props.data.longitude };

    return { center };
  },
});
</script>
