<template>
  <div class="container my-5">
    <div class="mb-3 text-center">
      <h3 class="d-inline me-3 mb-3 fw-bold">Join</h3>
      <BootstrapIcon
        class="add-btn primary"
        icon="plus-circle"
        @click="showModal"
      />
    </div>
    <table class="table table-hover table-bordered align-middle admin-table">
      <caption>
        Representative Information
      </caption>
      <thead class="table-light">
        <tr>
          <th scope="col" class="admin-table-preview">Preview</th>
          <th scope="col">First Name</th>
          <th scope="col">Last Name</th>
          <th scope="col">Role</th>
          <th scope="col">Option</th>
        </tr>
      </thead>
      <tbody>
        <tr :key="representative._id" v-for="representative in data">
          <AdminJoinTable :representative="representative" />
        </tr>
      </tbody>
    </table>
    <hr />
    <ModalPerson v-if="displayModal" @close-modal-event="hideModal" />
  </div>
</template>
<script>
import axios from "axios";
import ModalPerson from "./ModalPerson.vue";
import AdminJoinTable from "./AdminJoinTable.vue";
export default {
  name: "AdminJoin",
  components: {
    ModalPerson,
    AdminJoinTable,
  },
  data() {
    return {
      displayModal: false,
      data: null,
    };
  },
  methods: {
    showModal() {
      this.displayModal = true;
    },
    hideModal() {
      this.displayModal = false;
    },
  },
  created() {
    axios
      .get(this.$webDomain + "/estateInit/EstateJoin")
      .then((response) => (this.data = response.data.data));
  },
};
</script>
