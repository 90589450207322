<template>
  <div class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header bg-light">
          <h5 class="modal-title">Upload Image</h5>
          <button type="button" class="btn-close" @click="closeModal"></button>
        </div>
        <div class="modal-body">
          <form>
            <div class="form-group">
              <input type="file" class="form-control" @change="fileChange" />
            </div>
          </form>
        </div>
        <div class="modal-footer bg-light">
          <button type="button" class="btn btn-primary" @click="saveChanges">
            Upload
          </button>
          <button type="button" class="btn btn-secondary" @click="closeModal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import authHeader from "../../services/authHeader";
export default {
  name: "ModalListingEditPreviewImg",
  props: { id: String },
  data() {
    return {
      image: "",
      imageData: "",
    };
  },
  methods: {
    closeModal() {
      this.$emit("close-modal-event");
    },
    saveChanges() {
      // this.$parent.obj["mainImageType"] = "estate/listing";
      // this.$parent.obj["mainImageField"] = "mainImage";
      // this.$parent.obj["imageData"] = this.imageData;
      // console.log(this.$parent.obj);
      // this.closeModal();
      console.log(this.id);
      console.log(this.imageData);
      console.log(authHeader());
      axios
        .put(
          this.$webDomain + "/estate/EstateListing",
          {
            itemId: this.id,
            mainImageType: "estate/listing",
            mainImageField: "mainImage",
            imageData: this.imageData,
          },
          {
            headers: authHeader(),
          }
        )
        .then((response) => {
          console.log(response);
          window.location.reload();
        })
        .catch(function(error) {
          console.log(error);
        });
      this.$emit("close-modal-event");
    },
    fileChange(e) {
      this.image = e.target.files[0];
      const reader = new FileReader();
      reader.onloadend = (e) => {
        this.imageData = e.target.result;
      };
      reader.readAsDataURL(this.image);
    },
  },
};
</script>

<style scoped>
/* Override default value of 'none' */
.modal {
  display: block;
}
</style>
