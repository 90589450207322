<template>
  <div class="container my-5">
    <div class="mb-3 text-center">
      <h3 class="mb-3 fw-bold">Advertise</h3>
      <BootstrapIcon
        class="add-btn primary"
        icon="plus-circle"
        @click="showModal"
      />
    </div>
    <table class="table table-hover table-bordered align-middle admin-table">
      <caption>
        Advertise Images
      </caption>
      <thead class="table-light">
        <tr>
          <th scope="col" class="admin-table-preview">Preview</th>
          <!-- <th scope="col">Title in EN</th>
          <th scope="col">Title in CH</th>
          <th scope="col">Location Type</th> -->
          <th scope="col">Link</th>
          <th scope="col">Option</th>
        </tr>
      </thead>
      <tbody>
        <tr :key="adv._id" v-for="adv in posterData">
          <AdminAdvTable :adv="adv" />
        </tr>
      </tbody>
    </table>
    <hr />
    <ModalAdv v-if="displayModal" @close-modal-event="hideModal" />
  </div>
</template>
<script>
import { onMounted, computed } from "vue";
import store from "../../store";
import ModalAdv from "./ModalAdv.vue";
import AdminAdvTable from "./AdminAdvTable.vue";
export default {
  name: "AdminAdvertise",
  components: {
    ModalAdv,
    AdminAdvTable,
  },
  data() {
    return {
      displayModal: false,
    };
  },
  methods: {
    showModal() {
      this.displayModal = true;
    },
    hideModal() {
      this.displayModal = false;
    },
    // handleClick() {
    //   window.location.reload();
    // },
  },
  setup() {
    onMounted(() => {
      store.dispatch("fetchPosterData");
    });
    const posterData = computed(() => store.state.posterData);

    return {
      posterData,
    };
  },
};
</script>
