<template>
  <div id="carousel" class="carousel slide " data-bs-ride="carousel">
    <div class="carousel-indicators">
      <button
        type="button"
        v-for="(image, index) in data"
        :key="index"
        data-bs-target="#carousel"
        :data-bs-slide-to="index"
        :aria-label="'Slide' + ' ' + (index + 1)"
        :class="{ active: index == 0 }"
      ></button>
    </div>
    <div class="carousel-inner">
      <CarouselImage
        v-for="(image, index) in data"
        :index="index"
        :key="index"
        :image="image"
      />
    </div>
    <button
      class="carousel-control-prev"
      type="button"
      data-bs-target="#carousel"
      data-bs-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button
      class="carousel-control-next"
      type="button"
      data-bs-target="#carousel"
      data-bs-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
</template>

<script>
import axios from "axios";
import CarouselImage from "./CarouselImage.vue";
export default {
  name: "Carousel",
  components: { CarouselImage },
  data() {
    return {
      data: null,
    };
  },
  mounted() {
    axios
      .get(this.$webDomain + "/estateInit/EstateBasic")
      .then((response) => (this.data = response.data.data[0].topImageList));
  },
};
</script>

<style scoped>
#carousel {
  /* padding-top: 95px; */
  max-height: 90vh;
  overflow: hidden;
  /* max-height: 100hv; */
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #000;
}
</style>
