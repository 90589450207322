<template>
  <div class="basic pt-5 pb-3">
    <div class="container" v-if="!!data">
      <div class="title pt-3">
        <div class="d-inline-block">
          <h2>{{ data.mlsNumber }}</h2>
          <div class="bottom-line"></div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div>
            <div class="d-inline sub-title">{{ $t("about.basics.price") }}</div>
            <div class="d-inline">${{ data.price }}</div>
          </div>
          <div>
            <div class="d-inline sub-title">
              {{ $t("about.basics.intersection") }}
            </div>
            <div class="d-inline">{{ data.intersection }}</div>
          </div>
        </div>
        <div class="col">
          <div>
            <div class="d-inline sub-title">MLS® No:</div>
            <div class="d-inline">{{ data.mlsNumber }}</div>
          </div>
          <div>
            <div class="d-inline sub-title">
              {{ $t("about.basics.community") }}
            </div>
            <div class="d-inline">{{ data.community }}</div>
          </div>
        </div>
      </div>

      <div class="d-flex">
        <div class="d-inline sub-title">{{ $t("about.basics.address") }}</div>
        <div class="d-inline">
          {{ data.unit ? "Unit " + data.unit + " -" : "" }} {{ data.street }},
          {{ data.city }}, {{ data.province }}, {{ data.country }},
          {{ data.postcode }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Basics",
  props: {
    data: Object,
  },
};
</script>
<style scoped>
.container {
  margin-top: 100px;
}
.title {
  text-align: start;
}
.col {
  text-align: left;
}
.title,
.sub-title {
  color: #343a40;
  font-weight: bold;
}
.sub-title {
  margin-right: 5px;
}

.bottom-line {
  height: 4px;
  width: 4rem;
  background-color: #cb011b;
  display: block;
  margin: 0.7rem auto 1rem auto;
}
</style>
