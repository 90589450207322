<template>
  <div class="container d-flex align-items-center ">
    <div class="card m-auto p-5" v-if="!success">
      <div class="card-title text-center">
        <h3>Forget Password</h3>
      </div>
      <div class="card-body">
        <form @submit.prevent="handleSubmit" class="text-center">
          <!-- Email -->
          <div class="form-group">
            <label for="email">Your Email</label>
            <input type="email" id="email" v-model="userEmail" />
          </div>

          <div class="form-group pt-1">
            <button
              class="btn btn-sm btn-outline-primary d-block mx-auto"
              @click.prevent="sendLink"
            >
              Send
            </button>
            <div v-if="emailSent">
              <p class="mt-3 mb-1">A code will be sent to your email.</p>
              <p class="mb-0">Please enter the code and reset your password!</p>
            </div>
          </div>

          <!-- Code -->
          <div class="form-group">
            <label for="code">Enter Code</label>
            <input type="number" id="code" v-model="resetPasswordCode" />
          </div>

          <!-- New Password -->
          <div class="form-group">
            <label for="password">New Password</label>
            <input type="text" id="password" v-model="password" />
          </div>

          <!-- Submit -->
          <div class="form-group text-center">
            <button
              class="btn btn-primary btn-block"
              :disabled="loading"
              type="submit"
            >
              <span
                v-show="loading"
                class="spinner-border spinner-border-sm"
              ></span>
              <span>Submit</span>
            </button>
          </div>
        </form>
      </div>
    </div>

    <!-- After Password Reset Successfully -->
    <div class="card m-auto p-5 text-center" v-else>
      <div class="card-title text-center">
        <h3>Your Password has been reset successfully!</h3>
      </div>
      <div class="card-body">
        <p>Log in with your new password</p>
        <router-link to="/login" class="btn btn-primary btn-block mt-2"
          >Log In</router-link
        >
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import authHeader from "../../services/authHeader";

export default {
  name: "Login",
  data() {
    return {
      loading: false,
      emailSent: false,
      success: false,
      userEmail: "",
      resetPasswordCode: "",
      password: "",
    };
  },
  methods: {
    handleSubmit() {
      axios
        .put(
          this.$webDomain + "/auth/resetPasswordByCode",
          {
            email: this.userEmail,
            password: this.password,
            resetPasswordCode: this.resetPasswordCode,
          },
          {
            headers: authHeader(),
          }
        )
        .then((response) => {
          console.log(response);
          console.log("Password reset successfully.");
          this.success = true;
        })
        .catch((error) => {
          console.log("Something is wrong.");
          console.log(error);
        });
      return;
    },
    sendLink() {
      axios
        .get(
          this.$webDomain +
            "/auth/resetPasswordRequest?email=" +
            this.userEmail,
          {
            headers: authHeader(),
          }
        )
        .then((response) => {
          console.log("Code Sent successfully.");
          console.log(response);
        })
        .catch((error) => {
          console.log("Something is wrong.");
          console.log(error);
        });
      this.emailSent = true;
      return;
    },
  },
};
</script>

<style scoped>
html,
body {
  height: 100%;
}

.container {
  height: 100vh;
}

body {
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
}
.form-group {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}
h3 {
  margin: 20px;
}
label {
  display: block;
}
</style>
