<template>
  <td>
    <img
      :src="$webDomain + '/' + list.mainImageType + '/' + list.mainImage"
      alt="not found"
    />
  </td>
  <td v-if="!!info">{{ info.mlsNumber }}</td>
  <td v-if="!!info">{{ info.price }}</td>
  <td v-if="!!info">{{ info.intersection }}</td>
  <td v-if="!!info">
    {{
      info.unit + " " + info.street + ", " + info.city + ", " + info.province
    }}
  </td>

  <td class="options">
    <BootstrapIcon
      class="listTableIcon"
      icon="pencil-square"
      @click="showEdit()"
    />
    <BootstrapIcon
      class="listTableIcon delete"
      icon="trash"
      @click="deleteItem(list._id)"
    />
  </td>
  <ModalListingEdit
    :info="info"
    v-if="displayEdit"
    @close-modal-event="hideEdit"
    @reopen-listing-edit="reopenEdit"
  ></ModalListingEdit>
</template>
<script>
import axios from "axios";
import authHeader from "../../services/authHeader";
import ModalListingEdit from "./ModalListingEdit.vue";

export default {
  name: "AdminListTable",
  props: {
    list: Object,
  },
  components: {
    ModalListingEdit,
  },
  data() {
    return {
      info: null,
      displayEdit: false,
    };
  },
  mounted() {
    axios
      .get(this.$webDomain + "/estateInit/EstateListing/" + this.list._id)
      .then((response) => {
        this.info = response.data.data;
      });
  },
  methods: {
    deleteItem(id) {
      axios
        .delete(this.$webDomain + "/estate/EstateListing", {
          headers: authHeader(),
          data: {
            itemId: id,
          },
        })
        .then(function(response) {
          console.log(response);
          window.location.reload();
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    showEdit() {
      this.displayEdit = true;
    },
    hideEdit() {
      this.displayEdit = false;
    },
    reopenEdit() {
      // reload only the ModalListingEdit component
      this.displayEdit = false;
      this.$nextTick(function() {
        this.displayEdit = true;
      });
    },
  },
};
</script>
<style scoped>
img {
  width: 200px;
}
</style>
