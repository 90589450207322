<template>
  <div id="header" class="fixed-top">
    <nav class="navbar navbar-expand-lg">
      <div class="container-fluid">
        <!-- Logo Brand -->
        <div class="bg-white logo-container">
          <a class="navbar-brand me-0" href="#">
            <img
              :src="
                $webDomain + '/' + data.iconImageType + '/' + data.iconImage
              "
              alt="not found"
              class="d-inline-block align-text-top"
            />
          </a>
        </div>
        <!-- Company Name -->
        <h3 v-if="$i18n.locale === 'CH'" class="title">
          {{ data.companyNameCh }}
        </h3>
        <h3 v-if="$i18n.locale === 'EN'" class="title">
          {{ data.companyNameEn }}
        </h3>

        <!-- Hamburger Menu Button -->
        <button
          class="navbar-toggler me-3"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarToggler"
          aria-controls="navbarToggler"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <!-- Menu Button Icon -->
          <span>
            <font-awesome-icon :icon="['fas', 'stream']" />
          </span>
        </button>
        <!-- Nav Items -->
        <div class="collapse navbar-collapse" id="navbarToggler">
          <ul class="navbar-nav me-2 mt-2 mb-lg-0">
            <!-- Home Page -->
            <li class="nav-item">
              <router-link
                class="nav-link"
                to="/#"
                :class="{ active: currentPage == '' }"
                @click="activePage('')"
              >
                {{ $t("homePage.header.home") }}
              </router-link>
            </li>
            <!-- Introduction -->
            <li class="nav-item">
              <router-link
                class="nav-link"
                to="/#introduction"
                :class="{ active: currentPage == 'introduction' }"
                @click="activePage('introduction')"
              >
                {{ $t("homePage.header.introduction") }}
              </router-link>
            </li>
            <!-- Listing -->
            <li class="nav-item">
              <router-link
                class="nav-link"
                to="/#listing"
                :class="{ active: currentPage == 'listing' }"
                @click="activePage('listing')"
              >
                {{ $t("homePage.header.listing") }}
              </router-link>
            </li>
            <!-- Our Team -->
            <li class="nav-item">
              <router-link
                class="nav-link"
                to="/#group-members"
                :class="{ active: currentPage == 'group-members' }"
                @click="activePage('group-members')"
              >
                {{ $t("homePage.header.team") }}
              </router-link>
            </li>
            <!-- Contact Us -->
            <li class="nav-item">
              <router-link
                class="nav-link"
                to="/#contactInfo"
                :class="{ active: currentPage == 'contactInfo' }"
                @click="activePage('contactInfo')"
              >
                {{ $t("homePage.header.contact") }}
              </router-link>
            </li>

            <!-- Language Changing -->
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="dropdownLangMenu"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                >lang(语言)
              </a>
              <ul class="dropdown-menu" aria-labelledby="dropdownLangMenu">
                <li
                  v-for="locale in $i18n.availableLocales"
                  :key="`locale-${locale}`"
                >
                  <a
                    class="dropdown-item langBtn"
                    @click="changeLang(locale)"
                    >{{ locale === "CH" ? "中文" : "English" }}</a
                  >
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import axios from "axios";
import { getCurrentInstance } from "@vue/runtime-core";
export default {
  name: "Header",
  data() {
    return {
      data: "",
      currentPage: ""
    };
  },
  setup() {
    const ins = getCurrentInstance();
    // get logo image url
    const webDomain = ins.appContext.config.globalProperties.$webDomain;
    axios.get(webDomain + "/estateInit/EstateBasic").then(response => {
      ins.data.data = response.data.data[0];
    });
  },
  methods: {
    activePage(pageId) {
      this.currentPage = pageId;
    },
    toggleNav() {
      document.getElementById("navbarToggler").classList.remove("show");
    },
    changeLang(lang) {
      this.$i18n.locale = lang;
    }
  }
};
</script>

<style scoped>
nav {
  padding: 0 !important;
}

.nav-link {
  font-size: 16px !important;
}

.container-fluid {
  padding-left: 0;
}

.navbar-collapse {
  justify-content: end;
  text-align: end;
}

/* Language Dropdown Menu */
.dropdown-menu {
  top: 3rem !important;
  left: 3rem !important;
  min-width: 6rem;
  text-align: center;
}

.langBtn {
  cursor: pointer;
}
.title {
  color: #eee;
  padding-left: 20px;
}
.logo-container {
  width: auto;
  height: 78px;
}
a.navbar-brand {
  width: 100%;
  height: 100%;
  display: block;
  padding: 0;
  font-size: 0;
}
a.navbar-brand img {
  height: 100%;
}
@media screen and (max-width: 1237px) {
  .title {
    flex: 1;
    min-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
