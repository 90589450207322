<template>
  <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3 g-lg-4 ">
    <div class="col card-hover" :key="cardInfo._id" v-for="cardInfo in data">
      <Card :card="cardInfo" />
    </div>
  </div>
</template>

<script>
import Card from "./Card.vue";
export default {
  name: "Cards",
  props: {
    data: Object,
  },
  components: {
    Card,
  },
};
</script>
<style scoped>
.row {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}

.card-hover {
  transition: transform 750ms cubic-bezier(0.2, 1, 0.3, 1);
}

.card-hover:hover {
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .card-hover:hover {
    transform: none;
  }
}
</style>
