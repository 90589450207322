<template>
  <div class="bootstrap-modal-no-jquery">
    <div class="modal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header bg-light">
            <h5 class="modal-title">Upload Image</h5>
            <button
              type="button"
              class="btn-close"
              @click="closeModal"
            ></button>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-group">
                <input type="file" class="form-control" @change="fileChange" />
              </div>
            </form>
          </div>
          <div class="modal-footer bg-light">
            <button type="button" class="btn btn-primary" @click="saveChanges">
              Upload
            </button>
            <button type="button" class="btn btn-secondary" @click="closeModal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalListingImage",
  props: { id: String },
  data() {
    return {
      image: null,
      imageData: "",
      imageUrl: null,
    };
  },
  methods: {
    closeModal() {
      this.$emit("close-modal-event");
    },
    saveChanges() {
      this.$parent.imageUrl = this.imageUrl;
      this.$parent.obj["mainImageType"] = "estate/listing";
      this.$parent.obj["mainImageField"] = "mainImage";
      this.$parent.obj["imageData"] = this.imageData;
      this.closeModal();
    },
    fileChange(e) {
      this.image = e.target.files[0];
      this.imageUrl = URL.createObjectURL(this.image);
      const reader = new FileReader();
      reader.onloadend = (e) => {
        this.imageData = e.target.result;
      };
      reader.readAsDataURL(this.image);
    },
  },
};
</script>

<style scoped>
/* Override default value of 'none' */
.modal {
  display: block;
}
</style>
