<template>
  <div class="carousel-item" :class="{ active: index == 0 }">
    <img
      :src="$webDomain + '/' + image.subImageType + '/' + image.subImage"
      alt="not found"
      class="d-block w-100"
    />
  </div>
</template>
<script>
export default {
  name: "CarouselImage",
  props: {
    image: Object,
    index: Number,
  },
};
</script>
<style scoped>
.carousel-item {
  width: 100%;
  overflow: hidden;
}

img {
  height: 90vh;
  width: 100%;
  object-fit: cover;
}

@media (max-width: 768px) {
  img {
    height: 70vh;
  }
}
</style>
