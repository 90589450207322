<template>
  <tr>
    <!-- Room Type -->
    <td>
      <div v-if="room.roomType === 1">
        {{ $t("about.room.roomTypeOptions.livingroom") }}
      </div>
      <div v-else-if="room.roomType === 2">
        {{ $t("about.room.roomTypeOptions.bedroom") }}
      </div>
      <div v-else-if="room.roomType === 3">
        {{ $t("about.room.roomTypeOptions.kitchen") }}
      </div>
      <div v-else-if="room.roomType === 4">
        {{ $t("about.room.roomTypeOptions.washroom") }}
      </div>
      <div v-else-if="room.roomType === 5">
        {{ $t("about.room.roomTypeOptions.balcony") }}
      </div>
      <!-- <div v-else>Primary Bedroom</div> -->
    </td>

    <!-- Room Width X Height -->
    <td>{{ room.roomWidth }} X {{ room.roomHeight }}</td>
    <!-- Room Unit Type -->
    <td>
      <div v-if="room.roomUnitType == '1'">
        {{ $t("about.room.roomUnitTypeOptions.meter") }}
      </div>
      <div v-else-if="room.roomUnitType == '2'">
        {{ $t("about.room.roomUnitTypeOptions.inch") }}
      </div>
      <div v-else-if="room.roomUnitType == '3'">
        {{ $t("about.room.roomUnitTypeOptions.feet") }}
      </div>
      <!-- <div v-else>Unknown</div> -->
    </td>
    <!-- Room Location Type -->
    <td>
      <div v-if="room.roomLocationType === 1">
        {{ $t("about.room.roomLocationTypeOptions.groundFloor") }}
      </div>
      <div v-else-if="room.roomLocationType === 2">
        {{ $t("about.room.roomLocationTypeOptions.secondFloor") }}
      </div>
      <div v-else-if="room.roomLocationType === 3">
        {{ $t("about.room.roomLocationTypeOptions.basement") }}
      </div>
      <!-- <div v-else>Flat</div> -->
    </td>
  </tr>
</template>
<script>
export default {
  name: "Room",
  props: {
    room: Object,
  },
};
</script>
<style scoped></style>
