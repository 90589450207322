<template>
  <div class="feature pt-5 pb-3">
    <div class="container" v-if="!!data">
      <!-- Feature Title -->
      <div class="title mb-2">
        <div class="d-inline-block">
          <!-- Title Text -->
          <div class="title fs-5">{{ $t("about.feature.features") }}</div>
          <!-- Bottom Line -->
          <div class="bottom-line"></div>
        </div>
      </div>
      <!-- Feature Details -->
      <div class="row">
        <!-- First Col -->
        <div class="col">
          <!-- Property Type -->
          <div>
            <div class="d-inline sub-title">{{ $t("about.feature.type") }}</div>

            <div class="d-inline" v-if="data.propertyType == '1'">
              {{ $t("about.feature.typeOptions.detached") }}
            </div>
            <div class="d-inline" v-else-if="data.propertyType == '2'">
              {{ $t("about.feature.typeOptions.semiDetached") }}
            </div>
            <div class="d-inline" v-else-if="data.propertyType == '3'">
              {{ $t("about.feature.typeOptions.attRowTwnHouse") }}
            </div>
            <div class="d-inline" v-else-if="data.propertyType == '4'">
              {{ $t("about.feature.typeOptions.condoApt") }}
            </div>
            <!-- <div class="d-inline" v-else>
              {{ $t("about.feature.unknown") }}
            </div> -->
          </div>
          <!-- Property Style -->
          <div>
            <div class="d-inline sub-title">
              {{ $t("about.feature.propertyStyle") }}
            </div>
            <div class="d-inline" v-if="data.propertyStyle == '1'">
              {{ $t("about.feature.propertyStyleOptions.twoStorey") }}
            </div>
            <div class="d-inline" v-else-if="data.propertyStyle == '2'">
              {{ $t("about.feature.propertyStyleOptions.threeStorey") }}
            </div>
            <div class="d-inline" v-else-if="data.propertyStyle == '3'">
              {{ $t("about.feature.propertyStyleOptions.apartment") }}
            </div>
            <div class="d-inline" v-else-if="data.propertyStyle == '4'">
              {{ $t("about.feature.propertyStyleOptions.bungalow") }}
            </div>
            <!-- <div class="d-inline" v-else>
              {{ $t("about.feature.unknown") }}
            </div> -->
          </div>
          <!-- Land Size -->
          <div>
            <div class="d-inline sub-title">
              {{ $t("about.feature.landSize") }}
            </div>
            <div class="d-inline" v-if="data.landWidth || data.landHeight">
              {{ data.landWidth }} X {{ data.landHeight }}
              {{ $t("about.feature.landSizeUnit") }}
            </div>
          </div>
          <!-- Exterior Finish -->
          <div>
            <div class="d-inline sub-title">
              {{ $t("about.feature.exteriorFinish") }}
            </div>
            <div class="d-inline" v-if="data.exteriorStyle == '1'">
              {{ $t("about.feature.exteriorFinishOptions.stone") }}
            </div>
            <div class="d-inline" v-else-if="data.exteriorStyle == '2'">
              {{ $t("about.feature.exteriorFinishOptions.brick") }}
            </div>
            <div class="d-inline" v-else-if="data.exteriorStyle == '3'">
              {{ $t("about.feature.exteriorFinishOptions.fiberCement") }}
            </div>
            <div class="d-inline" v-else-if="data.exteriorStyle == '4'">
              {{ $t("about.feature.exteriorFinishOptions.wood") }}
            </div>
            <!-- <div class="d-inline" v-else>
              {{ $t("about.feature.unknown") }}
            </div> -->
          </div>
          <!-- Basement -->
          <div>
            <div class="d-inline sub-title">
              {{ $t("about.feature.basement") }}
            </div>
            <div class="d-inline" v-if="data.basementStyle == '1'">
              {{ $t("about.feature.basementStyleOptions.finished") }}
            </div>
            <div class="d-inline" v-else-if="data.basementStyle == '2'">
              {{ $t("about.feature.basementStyleOptions.unfinished") }}
            </div>
            <div class="d-inline" v-else-if="data.basementStyle == '3'">
              {{ $t("about.feature.basementStyleOptions.none") }}
            </div>
            <!-- <div class="d-inline" v-else>
              {{ $t("about.feature.unknown") }}
            </div> -->
          </div>

          <!-- Garage Type -->
          <div>
            <div class="d-inline sub-title">
              {{ $t("about.feature.garageType") }}
            </div>
            <div class="d-inline" v-if="data.garageType == '1'">
              {{ $t("about.feature.garageTypeOptions.builtIn") }}
            </div>
            <div class="d-inline" v-else-if="data.garageType == '2'">
              {{ $t("about.feature.garageTypeOptions.attached") }}
            </div>
            <div class="d-inline" v-else-if="data.garageType == '3'">
              {{ $t("about.feature.garageTypeOptions.underground") }}
            </div>
            <div class="d-inline" v-else-if="data.garageType == '4'">
              {{ $t("about.feature.garageTypeOptions.none") }}
            </div>
            <!-- <div class="d-inline" v-else>
              {{ $t("about.feature.unknown") }}
            </div> -->
          </div>

          <!-- Central AC -->
          <div>
            <div class="d-inline sub-title">
              {{ $t("about.feature.centralAC") }}
            </div>
            <div class="d-inline" v-if="data.centralAC == '1'">
              {{ $t("about.feature.centralACOptions.no") }}
            </div>
            <div class="d-inline" v-else-if="data.centralAC == '2'">
              {{ $t("about.feature.centralACOptions.yes") }}
            </div>
            <!-- <div class="d-inline" v-else>
              {{ $t("about.feature.unknown") }}
            </div> -->
          </div>
          <!-- Fireplace -->
          <div>
            <div class="d-inline sub-title">
              {{ $t("about.feature.firePlace") }}
            </div>
            <div class="d-inline" v-if="data.firePlace == '1'">
              {{ $t("about.feature.firePlaceOptions.no") }}
            </div>
            <div class="d-inline" v-else-if="data.firePlace == '2'">
              {{ $t("about.feature.firePlaceOptions.yes") }}
            </div>
            <!-- <div class="d-inline" v-else>
              {{ $t("about.feature.unknown") }}
            </div> -->
          </div>
        </div>
        <!-- Second Col -->
        <div class="col">
          <!-- Bedrooms -->
          <div>
            <div class="d-inline sub-title">
              {{ $t("about.feature.bedrooms") }}
            </div>
            <div
              class="d-inline"
              v-if="data.bedroomsNumber2 && data.bedroomsNumber2 != 0"
            >
              {{ data.bedroomsNumber1 }} + {{ data.bedroomsNumber2 }}
            </div>
            <div class="d-inline" v-else>{{ data.bedroomsNumber1 }}</div>
          </div>
          <!-- Kitchens -->
          <div>
            <div class="d-inline sub-title">
              {{ $t("about.feature.kitchens") }}
            </div>
            <div class="d-inline">{{ data.kitchenNumber }}</div>
          </div>
          <!-- Bathroom -->
          <div>
            <div class="d-inline sub-title">
              {{ $t("about.feature.bathrooms") }}
            </div>
            <div class="d-inline">{{ data.bathroomNumber }}</div>
          </div>
          <!-- Garage -->
          <div>
            <div class="d-inline sub-title">
              {{ $t("about.feature.garage") }}
            </div>
            <div class="d-inline">{{ data.garageNumber }}</div>
          </div>
          <!-- Parkings -->
          <div>
            <div class="d-inline sub-title">
              {{ $t("about.feature.parkings") }}
            </div>
            <div class="d-inline">{{ data.parkingNumber }}</div>
          </div>
          <!-- Heat Source -->
          <div>
            <div class="d-inline sub-title">
              {{ $t("about.feature.heatSource") }}
            </div>
            <!-- <div class="d-inline">{{ data.caption }}</div> -->
            <div class="d-inline" v-if="data.heatSource == '1'">
              {{ $t("about.feature.heatSourceOptions.gas") }}
            </div>
            <div class="d-inline" v-else-if="data.heatSource == '2'">
              {{ $t("about.feature.heatSourceOptions.oil") }}
            </div>
            <div class="d-inline" v-else-if="data.heatSource == '3'">
              {{ $t("about.feature.heatSourceOptions.water") }}
            </div>
            <div class="d-inline" v-else-if="data.heatSource == '4'">
              {{ $t("about.feature.heatSourceOptions.electrical") }}
            </div>
            <!-- <div class="d-inline" v-else>
              {{ $t("about.feature.unknown") }}
            </div> -->
          </div>
          <!-- Heat Type -->
          <div>
            <div class="d-inline sub-title">
              {{ $t("about.feature.heatType") }}
            </div>
            <!-- <div class="d-inline">{{ data.heatType }}</div> -->
            <div class="d-inline" v-if="data.heatType == '1'">
              {{ $t("about.feature.heatTypeOptions.forcedAir") }}
            </div>
            <div class="d-inline" v-else-if="data.heatType == '2'">
              {{ $t("about.feature.heatTypeOptions.heatPump") }}
            </div>
            <div class="d-inline" v-else-if="data.heatType == '3'">
              {{ $t("about.feature.heatTypeOptions.boiler") }}
            </div>
            <!-- <div class="d-inline" v-else>
              {{ $t("about.feature.unknown") }}
            </div> -->
          </div>
          <!-- Pool -->
          <div>
            <div class="d-inline sub-title">{{ $t("about.feature.pool") }}</div>
            <div class="d-inline" v-if="data.poolType == '1'">
              {{ $t("about.feature.poolOptions.no") }}
            </div>
            <div class="d-inline" v-else-if="data.poolType == '2'">
              {{ $t("about.feature.poolOptions.yes") }}
            </div>
            <!-- <div class="d-inline" v-else>
              {{ $t("about.feature.unknown") }}
            </div> -->
          </div>
        </div>
      </div>

      <!-- Description -->
      <!-- <p class="my-5">
        {{ data.description }}
      </p> -->
      <div class="my-5">
        <p v-if="$i18n.locale === 'CH'">
          {{ data.descriptionCh }}
        </p>
        <p v-if="$i18n.locale === 'EN'">
          {{ data.descriptionEn }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Feature",
  props: {
    data: Object,
  },
};
</script>
<style scoped>
.title,
p {
  text-align: start;
}
.col {
  text-align: left;
}
.title,
.sub-title {
  color: #343a40;
  font-weight: bold;
}

.sub-title {
  margin-right: 5px;
}

.bottom-line {
  height: 4px;
  width: 3rem;
  background-color: #cb011b;
  display: block;
  margin: 0.7rem auto 1rem auto;
}
</style>
