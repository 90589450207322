<template>
  <div class="carousel-item" :class="{ active: index == 0 }">
    <img
      :src="$webDomain + '/' + image.subImageType + '/' + image.subImage"
      alt="not found"
    />
  </div>
</template>
<script>
export default {
  name: "Image",
  props: {
    image: Object,
    index: Number,
  },
};
</script>
<style scoped>
img {
  width: 100%;
  height: 500px;
}
</style>
