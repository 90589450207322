<template>
  <div class="bootstrap-modal-no-jquery">
    <div class="modal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header bg-light">
            <h5 class="modal-title">Add New Advertise</h5>
            <button
              type="button"
              class="btn-close"
              @click="closeModal"
            ></button>
          </div>
          <div class="modal-body  p-4">
          
                <form>
                  <div class="form-group">
                    <label for="fileInput" class="form-label">Image</label>
                    <input
                      type="file"
                      class="form-control mb-3"
                      id="fileInput"
                      @change="fileChange"
                    />
                    <!-- <div class="row">
                      <div class="col"> -->
                    <!-- <label for="locationType" class="form-label"
                          >LocationType</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          id="locationType"
                          v-model="locationType"
                        />
                        <label for="titleCh" class="form-label">TitleCh</label>
                        <input
                          type="text"
                          class="form-control"
                          id="titleCh"
                          v-model="titleCh"
                        />
                      </div>
                      <div class="col">
                        <label for="titleEn" class="form-label">TitleEn</label>
                        <input
                          type="text"
                          class="form-control"
                          id="titleEn"
                          v-model="titleEn"
                        /> -->
                    <label for="link" class="form-label">Link</label>
                    <input
                      type="text"
                      class="form-control"
                      id="link"
                      v-model="link"
                    />
                    <!-- </div>
                    </div> -->
                  </div>
                </form>
            
          </div>
          <div class="modal-footer bg-light">
            <button type="button" class="btn btn-primary" @click="saveChanges">
              Add
            </button>
            <button type="button" class="btn btn-secondary" @click="closeModal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import authHeader from "../../services/authHeader";
import axios from "axios";
export default {
  name: "ModalAdv",
  data() {
    return {
      image: null,
      link: "",
      locationType: "",
      titleCh: "",
      titleEn: "",
    };
  },
  methods: {
    closeModal() {
      this.$emit("close-modal-event");
    },
    fileChange(e) {
      this.image = e.target.files[0];
    },
    saveChanges() {
      const formData = new FormData();
      formData.append("uploads", this.image);
      formData.append("fileNameField", "mainImage");
      formData.append("fileLocationField", "mainImageType");
      formData.append("editorField", "ownerId");
      formData.append("locationType", this.locationType);
      formData.append("titleEn", this.titleEn);
      formData.append("titleCh", this.titleCh);
      formData.append("link", this.link);
      axios
        .post(
          this.$webDomain + "/estate/EstatePosterFile?location=estate/poster",
          formData,
          { headers: authHeader() }
        )
        .then(function(response) {
          console.log(response);
          window.location.reload();
        })
        .catch(function(error) {
          console.log(error);
        });
      this.closeModal();
    },
  },
};
</script>

<style scoped>
.modal {
  display: block;
}

.form-label {
  margin-top: 0.5rem;
}
</style>
