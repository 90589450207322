<template>
  <div class="container d-flex align-items-center ">
    <div class="card m-auto p-5">
      <div class="card-title">
        <h3>User Log in</h3>
      </div>
      <div class="card-body">
        <Form @submit="handleLogin">
          <div class="form-group">
            <label for="email">Email</label>
            <Field name="email" type="text" class="form-control" />
            <ErrorMessage name="email" class="error-feedback" />
          </div>
          <div class="form-group">
            <label for="password">Password</label>
            <Field name="password" type="password" class="form-control" />
            <ErrorMessage name="password" class="error-feedback" />
          </div>

          <div class="form-group text-center" v-if="!!message">
            <p>{{ message }}</p>
          </div>

          <div class="form-group text-center">
            <button
              class="btn btn-primary btn-block me-5"
              :disabled="loading"
              type="submit"
            >
              <span
                v-show="loading"
                class="spinner-border spinner-border-sm"
              ></span>
              <span>Login</span>
            </button>

            <router-link to="/forgotPassword">
              <button class="btn btn-primary btn-block">
                <span>Forgot Password?</span>
              </button>
            </router-link>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default {
  name: "Login",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      email: yup.string().required("Username is required!"),
      password: yup.string().required("Password is required!"),
    });

    return {
      loading: false,
      message: "",
      schema,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/admin");
    }
  },
  methods: {
    handleLogin(user) {
      this.loading = true;
      this.$store.dispatch("auth/login", user).then(
        (response) => {
          console.log(response);
          const userEmail = response.data.userEmail;
          localStorage.setItem("userEmail", userEmail);
          this.$router.push("/admin");
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          console.log(this.message);
        }
      );
    },
  },
};
</script>
<style scoped>
html,
body {
  height: 100%;
}

.container {
  height: 100vh;
}

body {
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
}
.form-group {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}
h3 {
  margin: 20px;
}
</style>
