<template>
  <div class="container">
    <div class="card mx-auto" style="width: 30rem;">
      <div class="card-body">
        <h4 class="card-title text-center mt-3 mb-4 fw-bold">
          Change Password
        </h4>
        <hr />
        <!-- User Email -->
        <div class="mb-3 mt-4" :class="{ invalid: !userEmail.isValid }">
          <label class="form-label" for="userEmail">User Email</label>
          <input
            type="email"
            class="form-control"
            id="userEmail"
            v-model.trim="userEmail.val"
            @change="update('email', userEmail.val)"
            @blur="clearValidity('userEmail')"
          />
          <p v-if="!userEmail.isValid">Please enter valid User Email</p>
        </div>
        <!-- Old password -->
        <div class="mb-3" :class="{ invalid: !oldPassword.isValid }">
          <label class="form-label" for="oldPassword">Old Password</label>
          <input
            type="text"
            class="form-control"
            id="oldPassword"
            v-model.trim="oldPassword.val"
            @change="update('oldPassword', oldPassword.val)"
            @blur="clearValidity('oldPassword')"
          />
          <p v-if="!oldPassword.isValid">Old Password must not be empty</p>
        </div>
        <!-- New password -->
        <div class="mb-3" :class="{ invalid: !newPassword.isValid }">
          <label class="form-label" for="newPassword">New Password</label>
          <input
            type="text"
            class="form-control"
            id="newPassword"
            v-model.trim="newPassword.val"
            @change="update('password', newPassword.val)"
            @blur="clearValidity('newPassword')"
          />
          <p v-if="!newPassword.isValid">
            New Password length must longer than 6
          </p>
        </div>
        <!-- Repeat new password -->
        <div class="mb-3" :class="{ invalid: !repeatNewPassword.isValid }">
          <label class="form-label" for="repeatNewPassword"
            >Repeat New Password</label
          >
          <input
            type="text"
            class="form-control"
            id="repeatNewPassword"
            v-model.trim="repeatNewPassword.val"
            @change="update('repeatNewPassword', repeatNewPassword.val)"
            @blur="clearValidity('repeatNewPassword')"
          />
          <p v-if="!repeatNewPassword.isValid">
            Must match New Password
          </p>
        </div>
      </div>

      <div class="card-footer text-center">
        <div v-if="isLoading" class="mx-auto py-2">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <button
          v-else
          class="btn btn-primary m-3"
          type="submit"
          @click.prevent="submitChanges"
        >
          Save All
        </button>
        <p v-show="!!error" class="text-danger">
          Please check entered email and password.
        </p>
        <p v-show="!!response && error === null" class="text-success">
          Your password has been reset!
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import authHeader from "../../services/authHeader";

export default {
  data() {
    return {
      formIsValid: true,
      error: null,
      response: null,
      isLoading: false,
      userEmail: { val: localStorage.getItem("userEmail"), isValid: true },
      oldPassword: { val: "", isValid: true },
      newPassword: { val: "", isValid: true },
      repeatNewPassword: { val: "", isValid: true },
      obj: {},
    };
  },
  methods: {
    // Function to clear the red error when input lose focus
    clearValidity(input) {
      this[input].isValid = true;
    },
    validateForm() {
      // reset
      this.formIsValid = true;
      this.error = null;
      this.response = null;

      if (this.userEmail.val === "" || !this.userEmail.val.includes("@")) {
        this.userEmail.isValid = false;
        this.formIsValid = false;
      }
      if (this.oldPassword.val === "") {
        this.oldPassword.isValid = false;
        this.formIsValid = false;
      }
      if (this.newPassword.val === "" || this.newPassword.val.length < 6) {
        this.newPassword.isValid = false;
        this.formIsValid = false;
      }
      if (this.repeatNewPassword.val === "") {
        this.repeatNewPassword.isValid = false;
        this.formIsValid = false;
      }

      // When new password doesn't match
      if (this.newPassword.val !== this.repeatNewPassword.val) {
        console.log("New password doesn't match!");
        this.repeatNewPassword.isValid = false;
        this.formIsValid = false;
        return;
      }
    },
    update(key, value) {
      if (key === "oldPassword" || key === "password" || key === "email") {
        this.obj[key] = value;
      } else {
        return;
      }
    },
    submitChanges() {
      this.validateForm();

      // If form is not valid, do not submit
      if (!this.formIsValid) {
        console.log("Not Valid From");
        return;
      }
      console.log("Valid Form!");

      // start loading spinner
      this.isLoading = true;

      axios
        .post(this.$webDomain + "/auth/resetPasswordFromAccount", this.obj, {
          headers: authHeader(),
        })
        .then((response) => {
          console.log(response);
          this.response = response.data.data.message;
          console.log(response.data.data.message);
          // finish loading spinner
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
          // finish loading spinner
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
.invalid label,
.invalid p {
  color: red;
}

.invalid input {
  border: 1px solid red;
}

.card {
  margin-top: 10rem;
}
</style>
