<template>
  <td>
    <img
      :src="$webDomain + '/' + image.subImageType + '/' + image.subImage"
      alt="not found"
    />
  </td>
  <td class="pt-4">
    <BootstrapIcon
      class="listTableIcon delete"
      icon="trash"
      @click="deleteItem(image._id)"
    />
  </td>
</template>
<script>
import axios from "axios";
import authHeader from "../../services/authHeader";
export default {
  name: "AdminTopImgTable",
  props: {
    image: Object,
  },
  data() {
    return {
      displayEdit: false,
    };
  },
  methods: {
    deleteItem(id) {
      axios
        .put(
          this.$webDomain + "/estate/EstateBasicImageListDelete",
          {
            itemId: "62a57db66a5feb5f71e11eab",
            cellId: id,
            imageListField: "topImageList",
          },
          { headers: authHeader() }
        )
        .then(function(response) {
          console.log(response);
          window.location.reload();
        })
        .catch(function(error) {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
img {
  max-width: 300px;
}
</style>
