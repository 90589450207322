<template>
  <div class="bootstrap-modal-no-jquery">
    <div class="modal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header bg-light">
            <h5 class="modal-title">Add New Person</h5>
            <button
              type="button"
              class="btn-close"
              @click="closeModal"
            ></button>
          </div>
          <div class="modal-body  p-4">
            <form>
              <div class="form-group">
                <label for="fileInput" class="form-label">Image</label>
                <input
                  type="file"
                  class="form-control mb-3"
                  id="fileInput"
                  @change="fileChange"
                />

                <label for="firstName" class="form-label">First Name</label>
                <input
                  type="text"
                  class="form-control mb-3"
                  id="firstName"
                  v-model="firstName"
                />
                <label for="role" class="form-label">Role</label>
                <input
                  type="text"
                  class="form-control mb-3"
                  id="role"
                  v-model="role"
                />

                <label for="lastName" class="form-label">Last Name</label>
                <input
                  type="text"
                  class="form-control mb-3"
                  id="lastName"
                  v-model="lastName"
                />
              </div>
            </form>
          </div>
          <div class="modal-footer bg-light">
            <button type="button" class="btn btn-primary" @click="saveChanges">
              Upload
            </button>
            <button type="button" class="btn btn-secondary" @click="closeModal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import authHeader from "../../services/authHeader";
import axios from "axios";
export default {
  name: "ModalPerson",
  data() {
    return {
      image: null,
      imageData: "",
      firstName: "",
      lastName: "",
      role: "",
    };
  },
  methods: {
    closeModal() {
      this.$emit("close-modal-event");
    },
    saveChanges() {
      axios
        .post(
          this.$webDomain + "/estate/EstateJoin",
          {
            mainImageType: "estate/join",
            mainImageField: "mainImage",
            firstName: this.firstName,
            lastName: this.lastName,
            jobTitle: this.role,
            imageData: this.imageData,
          },
          { headers: authHeader() }
        )
        .then(function(response) {
          console.log(response);
          window.location.reload();
        })
        .catch(function(error) {
          console.log(error);
        });
      this.closeModal();
    },
    fileChange(e) {
      this.image = e.target.files[0];
      const reader = new FileReader();
      reader.onloadend = (e) => {
        this.imageData = e.target.result;
      };
      reader.readAsDataURL(this.image);
    },
  },
};
</script>

<style scoped>
/* Override default value of 'none' */
.modal {
  display: block;
}

.form-label {
  margin-top: 0.5rem;
}
</style>
