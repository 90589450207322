<template>
  <div class="container">
    <div class="mt-5 mb-3 text-center">
      <h3 class="d-inline me-3 mb-3 fw-bold">Top Image</h3>
      <BootstrapIcon
        class="add-btn primary"
        icon="plus-circle"
        @click="showModal"
      />
    </div>

    <div v-if="data == ''" class="text-center mt-5">
      <AdminEmpty />
    </div>

    <div v-else>
      <table class="table table-hover table-bordered align-middle admin-table">
        <caption>
          Carousel Images
        </caption>
        <thead class="table-light">
          <tr>
            <th scope="col">Preview</th>
            <th scope="col">Option</th>
          </tr>
        </thead>
        <tbody>
          <tr :key="image._id" v-for="image in data">
            <AdminTopImgTable :image="image" />
          </tr>
        </tbody>
      </table>
      <hr />
    </div>

    <ModalTopImage v-if="displayModal" @close-modal-event="hideModal" />
  </div>
</template>

<script>
import axios from "axios";
import ModalTopImage from "./ModalTopImage.vue";
import AdminEmpty from "./AdminEmpty.vue";
import AdminTopImgTable from "./AdminTopImgTable.vue";

export default {
  name: "AdminTopImage",
  components: {
    ModalTopImage,
    AdminTopImgTable,
    AdminEmpty,
  },
  data() {
    return {
      displayModal: false,
      data: null,
    };
  },
  methods: {
    showModal() {
      this.displayModal = true;
    },
    hideModal() {
      this.displayModal = false;
    },
    // handleClick() {
    //   window.location.reload();
    // },
  },
  mounted() {
    axios
      .get(this.$webDomain + "/estateInit/EstateBasic")
      .then(
        (response) => (this.data = response.data.data[0].topImageList),
        console.log(this.data)
      );
  },
};
</script>

<style scoped>
.table {
  max-width: 1000px;
  margin: 1.5rem auto;
}
</style>
