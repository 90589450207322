<template>
  <div class="section-title-container">
    <h2 class="section-title">{{ title }}</h2>
    <div class="bottom-line"></div>
  </div>
</template>

<script>
export default {
  name: "Bar",
  props: {
    title: String,
  },
};
</script>

<style scoped>
.section-title {
  font-size: 36px;
  font-weight: 500;
  color: black;
}

.section-title-container {
  margin-bottom: 5rem;
  text-align: center;
  justify-content: center;
}
</style>
