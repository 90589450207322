<template>
  <div class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header bg-light">
          <h5 class="modal-title">Upload Image</h5>
          <button type="button" class="btn-close" @click="closeModal"></button>
        </div>
        <div class="modal-body">
          <form>
            <div class="form-group">
              <input type="file" class="form-control" @change="fileChange" />
            </div>
          </form>
        </div>
        <div class="modal-footer bg-light">
          <button type="button" class="btn btn-primary" @click="saveChanges">
            Upload
          </button>
          <button type="button" class="btn btn-secondary" @click="closeModal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import authHeader from "../../services/authHeader";
import axios from "axios";
export default {
  name: "ModalTopImage",
  data() {
    return {
      image: null,
      imageData: "",
    };
  },
  methods: {
    closeModal() {
      this.$emit("close-modal-event");
    },
    saveChanges() {
      if (this.imageData !== "") {
        axios
          .post(
            this.$webDomain + "/estate/EstateBasicImageList",
            {
              itemId: "62a57db66a5feb5f71e11eab",
              imageData: this.imageData,
              imageType: "estate/basic",
              imageListField: "topImageList",
            },
            { headers: authHeader() }
          )
          .then(function(response) {
            console.log(response);
            window.location.reload();
          })
          .catch(function(error) {
            console.log(error);
          });
        this.closeModal();
      } else {
        alert("Please upload image");
      }
    },
    fileChange(e) {
      this.image = e.target.files[0];
      const reader = new FileReader();
      reader.onloadend = (e) => {
        this.imageData = e.target.result;
      };
      reader.readAsDataURL(this.image);
    },
  },
};
</script>

<style scoped>
/* Override default value of 'none' */
.modal {
  display: block;
}
</style>
