<template>
  <td>
    <img
      :src="$webDomain + '/' + image.subImageType + '/' + image.subImage"
      alt="not found"
    />
  </td>
  <td>
    <BootstrapIcon
      class="listTableIcon delete"
      icon="trash"
      @click="deleteItem(image._id)"
    />
  </td>
  <ModalTopImageEdit
    :imageprop="image"
    v-if="displayEdit"
    @close-modal-event="hideEdit"
  ></ModalTopImageEdit>
</template>
<script>
import axios from "axios";
import authHeader from "../../services/authHeader";
import ModalTopImageEdit from "./ModalTopImageEdit.vue";
export default {
  name: "AdminTopImgTable",
  props: {
    image: Object,
    listId: String,
  },
  emits: ["reopen-modal-room"],
  components: {
    ModalTopImageEdit,
  },
  data() {
    return {
      imageList: null,
      displayEdit: false,
    };
  },

  methods: {
    deleteItem(id) {
      axios
        .put(
          this.$webDomain + "/estate/EstateListingImageListDelete",
          {
            itemId: this.listId,
            cellId: id,
            imageListField: "listingImageList",
          },
          { headers: authHeader() }
        )
        .then((response) => {
          console.log(response);
          this.reopenEdit();
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    reopenEdit() {
      this.$emit("reopen-modal-room");
    },
    showEdit() {
      this.displayEdit = true;
      console.log(this.image);
    },
    hideEdit() {
      this.displayEdit = false;
    },
  },
};
</script>

<style scoped>
.listTableIcon {
  font-size: 1.7rem;
  margin: 2rem 0.5rem;
}

img {
  width: 200px;
  height: 200px;
}
</style>
