<template>
  <div class="container py-5">
    <div id="carousel" class="carousel slide" data-bs-ride="carousel">
      <div class="carousel-inner">
         <Image v-for="(image, index) in images" :index="index" :key="index" :image="image" />
      </div>
      <button
        class="carousel-control-prev"
        type="button"
        data-bs-target="#carousel"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#carousel"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>

    <div class="text-center">
      <ImageButton v-for="(image, index) in images" :index="index" :key="index" :image="image" />

      <!-- <img
        src="../statics/pictures/carousel/image4.jpg"
        alt="..."
        class="rounded m-1 active"
        data-bs-target="#carousel"
        data-bs-slide-to="0"
        aria-current="true"
        aria-label="Slide 1"
      />
      <img
        src="../statics/pictures/carousel/image5.jpg"
        alt="..."
        class="rounded m-1"
        data-bs-target="#carousel"
        data-bs-slide-to="1"
        aria-label="Slide 2"
      />
      <img
        src="../statics/pictures/carousel/image6.jpg"
        alt="..."
        class="rounded m-1"
        data-bs-target="#carousel"
        data-bs-slide-to="2"
        aria-label="Slide 3"
      /> -->
    </div>
  </div>
</template>
<script>
import Image from "./Image.vue";
import ImageButton from "./ImageButton.vue";
export default {
  name: "AboutCarousel",
  props:{
    images:Array,
  },
  components:{
    Image,
    ImageButton,
  }
};
</script>
<style scoped>
.rounded {
  height: 100px;
  width: 100px;
}

.carousel-inner {
  height: 500px;

}
img{
  width: 100%;
  height: 500px;
}
</style>