<template>
  <div class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header bg-light">
          <h5 class="modal-title">Edit Person</h5>
          <button type="button" class="btn-close" @click="closeModal"></button>
        </div>
        <div class="modal-body p-4">
          <form>
            <div class="form-group">
              <label for="fileInput" class="form-label">Image</label>
              <input
                type="file"
                class="form-control mb-3"
                id="fileInput"
                @change="fileChange"
              />

              <label for="firstName" class="form-label">First Name</label>
              <input
                type="text"
                class="form-control mb-3"
                id="firstName"
                v-model="firstName"
                @change="update('firstName', firstName)"
              />
              <label for="role" class="form-label">Role</label>
              <input
                type="text"
                class="form-control mb-3"
                id="role"
                v-model="role"
                @change="update('jobTitle', role)"
              />

              <label for="lastName" class="form-label">Last Name</label>
              <input
                type="text"
                class="form-control mb-3"
                id="lastName"
                v-model="lastName"
                @change="update('lastName', lastName)"
              />
            </div>
          </form>
        </div>
        <div class="modal-footer bg-light">
          <button type="button" class="btn btn-primary" @click="saveChanges">
            Save
          </button>
          <button type="button" class="btn btn-secondary" @click="closeModal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import authHeader from "../../services/authHeader";
import axios from "axios";
export default {
  name: "ModalPersonEdit",
  props: ["representative"],
  data() {
    return {
      firstName: this.representative.firstName,
      lastName: this.representative.lastName,
      role: this.representative.jobTitle,
      image: null,
      imageData: "",
      obj: {},
    };
  },
  methods: {
    closeModal() {
      this.$emit("close-modal-event");
    },
    saveChanges() {
      if (this.imageData) {
        this.obj["mainImageType"] = "estate/join";
        this.obj["mainImageField"] = "mainImage";
        this.obj["imageData"] = this.imageData;
      }

      this.obj["itemId"] = this.representative._id;
      axios
        .put(this.$webDomain + "/estate/EstateJoin", this.obj, {
          headers: authHeader(),
        })
        .then(function(response) {
          console.log(response);
          window.location.reload();
        })
        .catch(function(error) {
          console.log(error);
        });

      this.closeModal();
    },
    fileChange(e) {
      this.image = e.target.files[0];
      const reader = new FileReader();
      reader.onloadend = (e) => {
        this.imageData = e.target.result;
      };
      reader.readAsDataURL(this.image);
    },
    update(key, value) {
      if (key === "firstName" || key === "jobTitle" || key === "lastName") {
        this.obj[key] = value;
      }
    },
  },
};
</script>

<style scoped>
/* Override default value of 'none' */
.modal {
  display: block;
}

.form-label {
  margin-top: 0.5rem;
}
</style>
