<template>
  <img
    :src="$webDomain + '/' + image.subImageType + '/' + image.subImage"
    alt="not found"
    class="rounded m-1 "
    data-bs-target="#carousel"
    :data-bs-slide-to="index"
    :aria-current="{ true: index == 0 }"
    :aria-label="'Slide ' + toNumber()"
    :class="{ active: index == 0 }"
  />
</template>
<script>
export default {
  name: "ImageButton",
  props: {
    image: Object,
    index: Number,
  },
  methods: {
    toNumber() {
      return this.index + 1;
    },
  },
};
</script>
