// 准备翻译的语言环境信息
const messages = {
  EN: {
    // Home Page
    homePage: {
      // hello: "hello world",
      // Main Nav
      header: {
        home: "Home",
        introduction: "Introduction",
        listing: "Listing",
        team: "Our Team",
        contact: "Contact Us",
      },
      // Intro
      introduction: {
        title: "About Us",
        text: "{introduction}",
      },
      // Listing
      listing: {
        title: "Office Listings",
        details: "Details",
        price: "Price",
      },
      // Events
      events: {
        title: "Company Events",
      },
      // Team
      team: {
        title: "Our Team",
      },
      // Adv
      adv: {
        title: "Sponsors",
      },
      // Chat Button
      chatBtn: {
        chat: "Chat",
        form: {
          title: "Contact Us",
          name: "Name",
          namePlaceHolder: "Full Name",
          email: "Email",
          emailPlaceHolder: "Email Address",
          phone: "Phone",
          phonePlaceHolder: "Phone Number",
          message: "Message",
          messagePlaceHolder: "Message Content",
          send: "Send",
        },
      },
      // Footer
      footer: {
        title: "Contact Information",
      },
    },
    // About (Listing Detail)
    about: {
      basics: {
        price: "Price:",
        intersection: "Intersection:",
        address: "Address:",
        community: "Community",
      },
      feature: {
        features: "Features",
        type: "Type:",
        typeOptions: {
          detached: "Detached",
          semiDetached: "Semi-Detached",
          attRowTwnHouse: "Att/Row/Twnhouse",
          condoApt: "Condo Apt",
        },
        landSize: "Land Size:",
        landSizeUnit: "Feet",
        exteriorFinish: "Exterior Finish:",
        exteriorFinishOptions: {
          stone: "Stone",
          brick: "Brick",
          fiberCement: "Fiber Cement",
          wood: "Wood",
        },
        bathrooms: "#Bathrooms:",
        garageType: "Garage Type:",
        garageTypeOptions: {
          builtIn: "Built-In",
          attached: "Attached",
          underground: "Underground",
          none: "None",
        },
        parkings: "Parkings:",
        centralAC: "Central AC:",
        centralACOptions: {
          no: "N",
          yes: "Y",
        },
        firePlace: "Fire Place:",
        firePlaceOptions: {
          no: "N",
          yes: "Y",
        },
        propertyStyle: "Property Style:",
        propertyStyleOptions: {
          twoStorey: "2-Storey",
          threeStorey: "3-Storey",
          apartment: "Apartment",
          bungalow: "Bungalow",
        },
        basement: "Basement:",
        basementStyleOptions: {
          finished: "Finished",
          unfinished: "Unfinished",
          none: "None",
        },
        bedrooms: "#Bedrooms:",
        kitchens: "#Kitchens:",
        garage: "#Garage:",
        heatSource: "Heat Source:",
        heatSourceOptions: {
          gas: "Gas",
          oil: "Oil",
          water: "Water",
          electrical: "Electrical",
        },
        heatType: "Heat Type:",
        heatTypeOptions: {
          forcedAir: "Forced Air",
          heatPump: "Heat Pump",
          boiler: "Boiler",
        },
        pool: "Pool:",
        poolOptions: {
          no: "N",
          yes: "Y",
        },
        unknown: "Unknown",
      },
      room: {
        rooms: "Rooms",
        roomType: "Room Type",
        roomTypeOptions: {
          livingroom: "Livingroom",
          bedroom: "Bedroom",
          kitchen: "Kitchen",
          washroom: "Washroom",
          balcony: "Balcony",
        },
        size: "Size",
        roomLocationType: "Location Type",
        roomLocationTypeOptions: {
          groundFloor: "Ground Floor",
          secondFloor: "Second Floor",
          basement: "Basement",
        },
        roomUnitType: "Unit",
        roomUnitTypeOptions: {
          meter: "Meter",
          inch: "Inch",
          feet: "Feet",
        },
        description: "Description",
      },
    },
  },
  CH: {
    // Home Page
    homePage: {
      // hello: "你好，世界",
      // Main Nav
      header: {
        home: "首页",
        introduction: "关于我们",
        listing: "房源",
        team: "我们的团队",
        contact: "保持联系",
      },
      // Intro
      introduction: {
        title: "关于我们",
      },
      // Listing
      listing: {
        title: "官方房源",
        details: "具体信息",
      },
      // Events
      events: {
        title: "近期活动",
      },
      // Team
      team: {
        title: "我们的团队",
      },
      // Adv
      adv: {
        title: "广告商",
      },
      // Chat Button
      chatBtn: {
        chat: "咨询",
        form: {
          title: "欢迎询问",
          name: "姓名",
          namePlaceHolder: "全名",
          email: "邮箱",
          emailPlaceHolder: "邮箱地址",
          phone: "电话",
          phonePlaceHolder: "电话号码",
          message: "内容",
          messagePlaceHolder: "请输入内容",
          send: "发送",
        },
      },
      // Footer
      footer: {
        title: "联络方式",
      },
    },
    // About (Listing Detail)
    about: {
      // Basics
      basics: {
        price: "价格:",
        intersection: "路口:",
        address: "地址:",
        community: "社区:",
      },
      // Features
      feature: {
        features: "特点:",
        type: "类型:",
        typeOptions: {
          detached: "独立住宅",
          semiDetached: "半独立住宅",
          attRowTwnHouse: "连排别墅",
          condoApt: "多层公寓",
        },
        landSize: "建筑面积:",
        landSizeUnit: "英尺",
        exteriorFinish: "外部装修:",
        exteriorFinishOptions: {
          stone: "石头",
          brick: "砖",
          fiberCement: "纤维水泥",
          wood: "木制",
        },
        bathrooms: "#卫生间:",
        garageType: "车库类型:",
        garageTypeOptions: {
          builtIn: "嵌入式",
          attached: "分离式",
          underground: "地下",
          none: "无",
        },
        parkings: "#车位:",
        centralAC: "中央空调:",
        centralACOptions: {
          no: "无",
          yes: "有",
        },
        firePlace: "壁炉:",
        firePlaceOptions: {
          no: "无",
          yes: "有",
        },
        propertyStyle: "建筑风格:",
        propertyStyleOptions: {
          twoStorey: "二层住宅",
          threeStorey: "三层住宅",
          apartment: "公寓",
          bungalow: "平房",
        },
        basement: "地下室:",
        basementStyleOptions: {
          finished: "已装修",
          unfinished: "未装修",
          none: "无",
        },
        bedrooms: "#卧室:",
        kitchens: "#厨房:",
        garage: "#车库:",
        heatSource: "暖气源:",
        heatSourceOptions: {
          gas: "天然气",
          oil: "油",
          water: "水暖",
          electrical: "电暖",
        },
        heatType: "暖气类型:",
        heatTypeOptions: {
          forcedAir: "强风",
          heatPump: "散热器",
          boiler: "暖炉",
        },
        pool: "泳池:",
        poolOptions: {
          no: "无",
          yes: "有",
        },
        unknown: "未知",
      },
      // Room
      room: {
        rooms: "房间",
        roomType: "房间类型",
        roomTypeOptions: {
          livingroom: "客厅",
          bedroom: "卧室",
          kitchen: "厨房",
          washroom: "卫生间",
          balcony: "阳台",
        },
        size: "面积",
        roomLocationType: "所处位置",
        roomLocationTypeOptions: {
          groundFloor: "一楼",
          secondFloor: "二楼",
          basement: "地下室",
        },
        roomUnitType: "单位",
        roomUnitTypeOptions: {
          meter: "米",
          inch: "英寸",
          feet: "英尺",
        },
        description: "描述",
      },
    },
  },
};

export default messages;
