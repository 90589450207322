<template>
  <a :href="'//' + adv.link" target="_blank"
    ><img
      :src="$webDomain + '/' + adv.mainImageType + '/' + adv.mainImage"
      alt="not found"
  /></a>
</template>
<script>
export default {
  name: "Adv",
  props: {
    adv: Object,
  },
  // mounted() {
  //   console.log(this.adv.link);
  // },
};
</script>
<style scoped>
img {
  width: 230px;
  height: 100px;
}
</style>
