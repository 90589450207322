import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap";
import BootstrapIcon from "@dvuckovic/vue3-bootstrap-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faStream } from "@fortawesome/free-solid-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faFax } from "@fortawesome/free-solid-svg-icons";
import { faHatWizard } from "@fortawesome/free-solid-svg-icons";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
library.add(faStream);
library.add(faPhone);
library.add(faEnvelope);
library.add(faFax);
library.add(faHatWizard);
library.add(faCaretDown);
// Custom Styles
import "./assets/css/style.scss";
import globalProperties from "./globalProperties.js";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

// v-i18n
import { createI18n } from "vue-i18n";
import messages from "./vi18n/messages";

const i18n = createI18n({
  locale: "EN", // set locale
  fallbackLocale: "CH", // set fallback locale
  messages
});

// tab name
document.title = "HomeComfort Realty";

const app = createApp(App);

// Global Properties
// Web Domain
app.config.globalProperties.$webDomain = globalProperties.webDomain;

app
  .use(store)
  .use(router)
  .use(i18n)
  .component("font-awesome-icon", FontAwesomeIcon)
  .component("BootstrapIcon", BootstrapIcon)
  .mount("#app");
