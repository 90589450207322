
import { onMounted, computed } from "vue";
import store from "../store";
// Real Estate Components
import Header from "../components/Home/Header.vue";
import Carousel from "../components/Home/Carousel.vue";
import Cards from "../components/Home/Cards.vue";
import ImgAnimation from "../components/Home/ImgAnimation.vue";
import Teams from "../components/Home/Teams.vue";
import Advertisements from "../components/Home/Advertisements.vue";
import Bar from "../components/Home/Bar.vue";
import IntroductionBar from "../components/Home/IntroductionBar.vue";
import Footer from "../components/Footer.vue";
import ChatButton from "../components/ChatButton.vue";

export default {
  name: "Home",

  components: {
    // Real Estate Components
    Header,
    Carousel,
    Cards,
    ImgAnimation,
    Teams,
    Advertisements,
    Bar,
    IntroductionBar,
    Footer,
    ChatButton
  },

  setup() {
    onMounted(() => {
      store.dispatch("fetchEventData");
      store.dispatch("fetchImageData");
      store.dispatch("fetchInfoData");
      store.dispatch("fetchListingData");
      store.dispatch("fetchPosterData");
    });

    const eventData = computed(() => store.state.eventData);
    const imageData = computed(() => store.state.imageData);
    const infoData = computed(() => store.state.infoData);
    const listingData = computed(() => store.state.listingData);
    const posterData = computed(() => store.state.posterData);

    return {
      eventData,
      imageData,
      infoData,
      listingData,
      posterData
    };
  }
};
