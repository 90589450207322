<template>
  <!-- Room Type -->
  <td v-if="!!rooms">{{ roomTypeOption[roomTypeValue - 1] }}</td>

  <!-- Width -->
  <td v-if="!!rooms">{{ rooms.roomWidth }}</td>

  <!-- Height -->
  <td v-if="!!rooms">{{ rooms.roomHeight }}</td>

  <!-- Location Type -->
  <td v-if="!!rooms">
    {{ roomLocationTypeOption[roomLocationTypeValue - 1] }}
  </td>

  <!-- Unit Type -->
  <td v-if="!!rooms">{{ roomUnitTypeOption[roomUnitTypeValue - 1] }}</td>

  <!-- Options Button -->
  <td>
    <BootstrapIcon
      class="listTableIcon edit"
      icon="pencil-square"
      @click="showEdit()"
    />
    <BootstrapIcon
      class="listTableIcon delete"
      icon="trash"
      @click="deleteItem(rooms._id)"
    />
  </td>
  <ModalListingEditRoomTableEdit
    :listId="listId"
    :rooms="rooms"
    v-if="displayEdit"
    @close-modal-event="hideEdit"
    @reopen-modal-room="reopenEdit"
  />
</template>
<script>
import axios from "axios";
import authHeader from "../../services/authHeader";
import ModalListingEditRoomTableEdit from "./ModalListingEditRoomTableEdit.vue";

export default {
  name: "ModalListingEditRoomTable",
  props: ["rooms", "listId"],
  emits: ["reopen-modal-room"],
  components: { ModalListingEditRoomTableEdit },
  data() {
    return {
      displayEdit: false,

      roomTypeValue: this.rooms.roomType,
      roomLocationTypeValue: this.rooms.roomLocationType,
      roomUnitTypeValue: this.rooms.roomUnitType,

      roomTypeOption: [
        "Livingroom",
        "Bedroom",
        "Kitchen",
        "Washroom",
        "Balcony",
      ],
      roomLocationTypeOption: ["Ground Floor", "Second Floor", "Basement"],
      roomUnitTypeOption: ["meter", "inch", "feet"],
    };
  },
  methods: {
    deleteItem(id) {
      axios
        .put(
          this.$webDomain + "/estate/EstateListingListDelete",
          {
            itemId: this.listId,
            cellId: id,
            subItemListField: "roomList",
          },
          {
            headers: authHeader(),
          }
        )
        .then((response) => {
          console.log(response);
          this.reopenEdit();
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    reopenEdit() {
      this.$emit("reopen-modal-room");
    },
    showEdit() {
      this.displayEdit = true;
      // console.log("List Id: " + this.listId);
      // console.log("Room Id: " + this.rooms._id);
    },
    hideEdit() {
      this.displayEdit = false;
    },
  },
};
</script>
