<template>
  <div class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header bg-light">
          <h5 class="modal-title">Add New Event</h5>
          <button type="button" class="btn-close" @click="closeModal"></button>
        </div>
        <div class="modal-body p-4">
          <form>
            <div class="form-group">
              <label for="fileChange" class="form-label">Image</label>
              <div class="file-input mb-3">
                <input
                  type="file"
                  class="form-control"
                  id="fileChange"
                  @change="fileChange"
                />
              </div>
            </div>
            <div class="form-group">
              <label for="link" class="form-label">Link</label>
              <input
                type="text"
                class="form-control"
                id="link"
                v-model="link"
                @change="update('link', link)"
              />
            </div>
            <div class="form-group">
              <label for="eventTitleCh" class="form-label">EventTitleCh</label>
              <input
                type="text"
                class="form-control"
                id="eventTitleCh"
                v-model="eventTitleCh"
                @change="update('eventTitleCh', eventTitleCh)"
              />
            </div>
            <div class="form-group">
              <label for="eventContentCh" class="form-label"
                >EventContentCh</label
              >
              <textarea
                type="text"
                class="form-control content-input"
                id="eventContentCh"
                v-model="eventContentCh"
                @change="update('eventContentCh', eventContentCh)"
              />
            </div>
            <div class="form-group">
              <label for="eventTitleEn" class="form-label">EventTitleEn</label>
              <input
                type="text"
                class="form-control"
                id="eventTitleEn"
                v-model="eventTitleEn"
                @change="update('eventTitleEn', eventTitleEn)"
              />
            </div>
            <div class="form-group">
              <label for="eventContentEn" class="form-label"
                >EventContentEn</label
              >
              <textarea
                type="text"
                class="form-control content-input"
                id="eventContentEn"
                v-model="eventContentEn"
                @change="update('eventContentEn', eventContentEn)"
              />
            </div>
          </form>
        </div>
        <div class="modal-footer bg-light">
          <button type="button" class="btn btn-primary" @click="saveChanges">
            Upload
          </button>
          <button type="button" class="btn btn-secondary" @click="closeModal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import authHeader from "../../services/authHeader";
import axios from "axios";
export default {
  name: "ModalEvent",
  data() {
    return {
      image: null,
      imageData: "",
      link: "",
      eventTitleCh: "",
      eventTitleEn: "",
      eventContentCh: "",
      eventContentEn: "",
      obj: {},
    };
  },
  methods: {
    closeModal() {
      this.$emit("close-modal-event");
    },
    saveChanges() {
      if (this.imageData !== "") {
        this.obj["mainImageType"] = "estate/listing";
        this.obj["mainImageField"] = "mainImage";
        this.obj["imageData"] = this.imageData;

        axios
          .post(this.$webDomain + "/estate/EstateEvent", this.obj, {
            headers: authHeader(),
          })
          .then(function(response) {
            console.log(response);
            window.location.reload();
          })
          .catch(function(error) {
            console.log(error);
          });

        this.closeModal();
      } else {
        alert("Need to upload an image!");
      }
    },
    fileChange(e) {
      this.image = e.target.files[0];
      const reader = new FileReader();
      reader.onloadend = (e) => {
        this.imageData = e.target.result;
      };
      reader.readAsDataURL(this.image);
    },
    update(key, value) {
      if (
        key === "link" ||
        key === "eventTitleCh" ||
        key === "eventTitleEn" ||
        key === "eventContentCh" ||
        key === "eventContentEn"
      ) {
        this.obj[key] = value;
      }
    },
  },
};
</script>

<style scoped>
/* Override default value of 'none' */
.modal {
  display: block;
}

.form-group {
  margin-bottom: 1rem;
}

.form-label {
  margin-top: 0.5rem;
  text-align: left;
}

.content-input {
  height: 100px;
}
</style>
