<template>
  <div class="container my-5" v-if="!!data">
    <div class="mb-3 text-center">
      <h3 class="d-inline me-5 fw-bold">Basic Info</h3>
    </div>
    <div class="row">
      <!-- Company Name -->
      <div class="inner-container col-md-6 p-3">
        <div class="card h-100">
          <div class="card-body">
            <h5 class="card-title">Company Name</h5>
            <hr />
            <div class="form-floating mb-3">
              <input
                type="text"
                class="form-control"
                id="companyNameEn"
                v-model="companyNameEn"
                @change="update('companyNameEn', companyNameEn)"
              />
              <label class="form-label" for="companyNameEn"
                >Company Name in English</label
              >
            </div>
            <div class="form-floating mb-3">
              <input
                type="text"
                class="form-control"
                id="companyNameCh"
                v-model="companyNameCh"
                @change="update('companyNameCh', companyNameCh)"
              />
              <label class="form-label" for="companyNameCh"
                >Company Name in Chinese</label
              >
            </div>
          </div>
        </div>
      </div>
      <!-- Contact Information -->
      <div class="inner-container col-md-6 p-3">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Contact Information</h5>
            <hr />
            <div class="row">
              <div class="mb-3 col-12">
                <div class="form-floating ">
                  <input
                    type="text"
                    class="form-control"
                    id="inputEmail"
                    v-model="email"
                    @change="update('emailAddress', email)"
                  />
                  <label class="form-label" for="inputEmail">Email</label>
                </div>
              </div>
              <div class="mb-3 col-lg-6">
                <div class="form-floating ">
                  <input
                    type="text"
                    class="form-control"
                    id="inputPhone"
                    v-model="phoneNumber"
                    @change="update('phoneNumber', phoneNumber)"
                  />
                  <label class="form-label" for="inputPhone"
                    >Phone Number</label
                  >
                </div>
              </div>
              <div class="mb-3 col-lg-6">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    id="inputTax"
                    v-model="faxNumber"
                    @change="update('faxNumber', faxNumber)"
                  />
                  <label class="form-label" for="inputPhone">Fax Number</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <!-- Company Address -->
      <div class="inner-container col-md-6 p-3">
        <div class="card ">
          <div class="card-body">
            <h5 class="card-title">Company Address</h5>
            <hr />
            <div class="form-floating mb-3">
              <input
                type="text"
                class="form-control"
                id="inputAddress"
                v-model="street"
                @change="update('street', street)"
              />
              <label class="form-label" for="inputAddress">Street</label>
            </div>
            <div class="form-floating mb-3">
              <input
                type="text"
                class="form-control"
                id="inputCity"
                v-model="city"
                @change="update('city', city)"
              />
              <label class="form-label" for="inputCity">City</label>
            </div>
            <div class="form-floating mb-3">
              <input
                type="text"
                class="form-control"
                id="inputState"
                v-model="province"
                @change="update('province', province)"
              />
              <label class="form-label" for="inputState">Province</label>
            </div>
            <div class="form-floating mb-3">
              <input
                type="text"
                class="form-control"
                id="inputZip"
                v-model="postcode"
                @change="update('postcode', postcode)"
              />
              <label class="form-label" for="inputZip">Post Code</label>
            </div>
            <div class="form-floating mb-3">
              <input
                type="text"
                class="form-control"
                id="inputLat"
                v-model="latitude"
                @change="update('latitude', latitude)"
              />
              <label class="form-label" for="inputLat">Latitude</label>
            </div>
            <div class="form-floating mb-3">
              <input
                type="text"
                class="form-control"
                id="inputLon"
                v-model="longitude"
                @change="update('longitude', longitude)"
              />
              <label class="form-label" for="inputLon">Longitude</label>
            </div>
          </div>
        </div>
      </div>
      <!-- Introduction -->
      <div class="inner-container col-md-6 p-3">
        <div class="card h-100">
          <div class="card-body">
            <h5 class="card-title">Introduction</h5>
            <hr />
            <div class="row h-100">
              <div class="mb-3 col-12">
                <div class="form-floating ">
                  <textarea
                    class="form-control "
                    id="descriptionEn"
                    v-model="descriptionEn"
                    @change="update('companyDescriptionEn', descriptionEn)"
                  ></textarea>
                  <label class="form-label" for="descriptionEn">English</label>
                </div>
              </div>
              <div class="mb-3 col-12">
                <div class="form-floating">
                  <textarea
                    class="form-control"
                    id="descriptionCh"
                    v-model="descriptionCh"
                    @change="update('companyDescriptionCh', descriptionCh)"
                  ></textarea>
                  <label class="form-label" for="descriptionCh">Chinese</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <!-- Logo Image -->
      <div class="inner-container col-md-6 p-3">
        <div class="card h-100">
          <div class="card-body">
            <h5 class="card-title">Logo Image</h5>
            <hr />
            <div class="row mb-3">
              <div class="col-md-6 d-flex flex-column">
                <label class="form-label col" for="inputZip">Preview</label>
                <img class="preview-logo" :src="logoImageUrl" alt="not found" />
              </div>
              <div class="col-md-6 pt-5 text-center">
                <button
                  type="button"
                  class="btn btn-secondary m-3"
                  @click="showModal"
                >
                  Change Logo
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Copy Right -->
      <div class="inner-container col-md-6 p-3">
        <div class="card h-100">
          <div class="card-body">
            <h5 class="card-title">Other</h5>
            <hr />
            <div class="form-floating">
              <input
                class="form-control"
                id="copyRight"
                v-model="copyRight"
                @change="update('copyRight', copyRight)"
              />

              <label class="form-label" for="copyRight">Copy Right</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Save Button -->
    <div class="text-center my-4">
      <button
        class="btn btn-primary btn-lg"
        type="submit"
        @click="submitChanges"
      >
        Save All
      </button>
    </div>

    <hr />

    <Modal v-if="displayModal" @close-modal-event="hideModal" />
  </div>
</template>

<script>
import Modal from "./Modal.vue";
import axios from "axios";
import authHeader from "../../services/authHeader";

export default {
  name: "AdminBasic",
  components: {
    Modal
  },
  computed: {
    logoImageUrl() {
      return this.$webDomain + "/" + this.iconImageType + "/" + this.iconImage;
    }
  },
  data() {
    return {
      displayModal: false,
      data: null,
      iconImage: "",
      iconImageType: "",
      companyNameEn: "",
      companyNameCh: "",
      email: "",
      street: "",
      city: "",
      province: "",
      postcode: "",
      phoneNumber: "",
      faxNumber: "",
      latitude: "",
      longitude: "",
      descriptionEn: "",
      descriptionCh: "",
      copyRight: "",
      obj: {}
    };
  },
  created() {
    axios.get(this.$webDomain + "/estateInit/EstateBasic").then(response => {
      (this.data = response.data.data[0]),
        (this.iconImage = response.data.data[0].iconImage),
        (this.iconImageType = response.data.data[0].iconImageType),
        (this.companyNameEn = response.data.data[0].companyNameEn),
        (this.companyNameCh = response.data.data[0].companyNameCh),
        (this.email = response.data.data[0].emailAddress),
        (this.street = response.data.data[0].street),
        (this.city = response.data.data[0].city),
        (this.province = response.data.data[0].province),
        (this.postcode = response.data.data[0].postcode),
        (this.phoneNumber = response.data.data[0].phoneNumber),
        (this.faxNumber = response.data.data[0].faxNumber),
        (this.latitude = response.data.data[0].latitude),
        (this.longitude = response.data.data[0].longitude),
        (this.descriptionEn = response.data.data[0].companyDescriptionEn),
        (this.descriptionCh = response.data.data[0].companyDescriptionCh);
      this.copyRight = response.data.data[0].copyRight;
    });
    this.refreshLogoImage = setInterval(() => {
      axios.get(this.$webDomain + "/estateInit/EstateBasic").then(response => {
        this.iconImage = response.data.data[0].iconImage;
        this.iconImageType = response.data.data[0].iconImageType;
      });
    }, 1000);
  },
  beforeUnmount() {
    clearInterval(this.refreshLogoImage);
  },
  methods: {
    showModal() {
      this.displayModal = true;
    },
    hideModal() {
      this.displayModal = false;
    },
    update(key, value) {
      if (key === "longitude" || key === "latitude") {
        this.obj[key] = parseFloat(value);
      } else {
        this.obj[key] = value;
      }
    },
    closeModal() {
      this.$emit("close-modal-event");
    },
    submitChanges() {
      this.obj["itemId"] = "62a57db66a5feb5f71e11eab";
      axios
        .put(this.$webDomain + "/estate/EstateBasic", this.obj, {
          headers: authHeader()
        })
        .then(function(response) {
          console.log(response);
          window.location.reload();
        })
        .catch(function(error) {
          console.log(error);
        });
    }
  }
};
</script>
<style scoped>
.card-body {
  padding: 1.5rem 1.5rem !important;
}

.logo {
  height: 100px;
}

.preview-logo {
  max-width: 280px;
  height: auto;
}

textarea {
  height: 180px !important;
}

.card-header {
  font-size: 1.3rem;
}
</style>
