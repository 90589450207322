<template>
  <router-link
    :to="{ name: 'details', params: { id: card._id } }"
    @click="scrollToTop"
  >
    <div class="card shadow">
      <img
        :src="$webDomain + '/' + card.mainImageType + '/' + card.mainImage"
        class="card-img-top"
        alt="not found"
      />
      <div class="card-body">
        <h5 class="card-title">
          {{ "$" + card.price }}
        </h5>
        <p class="card-text">{{ card.street }}, {{ card.postcode }}</p>
      </div>
      <div class="card-footer p-0">
        <button class="listing-detail-button">
          <a>{{ $t("homePage.listing.details") }}</a>
        </button>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "Card",
  props: {
    card: Object,
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>
<style scoped>
.card {
  margin: 1rem;
}

img {
  height: 100%;
  width: 100%;
}

a {
  font-size: 18px;
  text-decoration: none;
  color: white;
  border-bottom: 2px transparent solid;
  transition: border-color 0.3s;
}

a:hover {
  border-color: #ffe700;
}

.listing-detail-button {
  width: 100%;
  border: none;
  padding: 0.6rem 0;
}

.card-img-top {
  height: 300px;
}

.card-body {
  color: #000;
  text-align: center;
}

.card-title {
  text-align: center;
}
</style>
