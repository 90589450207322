import { Commit, createStore } from "vuex";
import axios from "axios";
import { EventModel } from "@/model/EventModel";
import { PosterModel } from "@/model/PosterModel";
import { ListingModel } from "@/model/ListingModel";
import { ImageModel } from "@/model/ImageModel";
import { InfoModel } from "@/model/InfoModel";
import { auth } from "./authModule.js";
// import { number } from "yup";
import globalProperties from "./globalProperties.js";

const webDomain = globalProperties.webDomain;

interface ListProps<P> {
  [id: string]: P;
}

export interface GlobalDataProps {
  eventData: ListProps<EventModel>;
  imageData: ListProps<ImageModel>;
  infoData: ListProps<InfoModel>;
  listingData: ListProps<ListingModel>;
  posterData: ListProps<PosterModel>;

  loading: boolean;
}

const asyncAndCommit = async (
  url: string,
  mutationName: string,
  commit: Commit
) => {
  const { data } = await axios.get(url);
  commit(mutationName, data);
  return data;
};
export default createStore<GlobalDataProps>({
  state: {
    eventData: {},
    imageData: {},
    infoData: {},
    listingData: {},
    posterData: {},
    loading: false,
  },

  mutations: {
    // Real Estate
    fetchEventData(state, rawData) {
      state.eventData = rawData.data;
    },
    fetchImageData(state, rawData) {
      state.imageData = rawData.data;
    },
    fetchInfoData(state, rawData) {
      state.infoData = rawData.data;
    },
    fetchListingData(state, rawData) {
      state.listingData = rawData.data;
    },
    fetchPosterData(state, rawData) {
      state.posterData = rawData.data;
    },

    setLoading(state, status) {
      state.loading = status;
    },
  },

  actions: {
    // Real Estate Data
    fetchEventData(context) {
      asyncAndCommit(
        webDomain + "/estateInit/EstateEvent",
        "fetchEventData",
        this.commit
      );
    },
    fetchImageData(context) {
      asyncAndCommit(
        webDomain + "/estateInit/EstateImage",
        "fetchImageData",
        this.commit
      );
    },
    fetchInfoData(context) {
      asyncAndCommit(
        webDomain + "/estateInit/EstateInfo",
        "fetchInfoData",
        this.commit
      );
    },
    fetchListingData(context) {
      asyncAndCommit(
        webDomain + "/estateInit/EstateListing?size=100",
        "fetchListingData",
        this.commit
      );
    },
    fetchPosterData(context) {
      asyncAndCommit(
        webDomain + "/estateInit/EstatePoster",
        "fetchPosterData",
        this.commit
      );
    },
  },

  modules: {
    auth,
  },
});
