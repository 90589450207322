<template>
  <div class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content ">
        <div class="modal-header bg-light">
          <h5 class="modal-title">Edit Advertise</h5>
          <button type="button" class="btn-close" @click="closeModal"></button>
        </div>
        <div class="modal-body  p-4">
          <form>
            <div class="form-group">
              <label for="fileChange" class="form-label">Image</label>
              <input
                type="file"
                class="form-control mb-3"
                id="fileChange"
                @change="fileChange"
              />
              <!-- <div class="row">
                    <div class="col">
                      <label for="locationType" class="form-label"
                        >LocationType</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="locationType"
                        v-model="locationType"
                      />
                      <label for="titleCh" class="form-label">TitleCh</label>
                      <input
                        type="text"
                        class="form-control"
                        id="titleCh"
                        v-model="titleCh"
                      />
                    </div>
                    <div class="col">
                      <label for="titleEn" class="form-label">TitleEn</label>
                      <input
                        type="text"
                        class="form-control"
                        id="titleEn"
                        v-model="titleEn"
                      /> -->
              <label for="link" class="form-label">Link</label>
              <input
                type="text"
                class="form-control"
                id="link"
                v-model="link"
              />
              <!-- </div>
                  </div> -->
            </div>
          </form>
        </div>
        <div class="modal-footer bg-light">
          <button type="button" class="btn btn-primary" @click="saveChanges">
            Save
          </button>
          <button type="button" class="btn btn-secondary" @click="closeModal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import authHeader from "../../services/authHeader";
import axios from "axios";
export default {
  name: "ModalAdvEdit",
  props: ["adv"],
  data() {
    return {
      link: this.adv.link,
      locationType: this.adv.locationType,
      titleCh: this.adv.titleCh,
      titleEn: this.adv.titleEn,
      obj: {
        image: null,
        imageData: "",
      },
    };
  },
  methods: {
    closeModal() {
      this.$emit("close-modal-event");
    },
    saveChanges() {
      console.log("save btn pressed");
      axios
        .put(
          this.$webDomain + "/estate/EstatePoster",
          {
            itemId: this.adv._id,
            mainImageType: "estate/poster",
            mainImageField: "mainImage",
            link: this.link,
            locationType: this.locationType,
            titleCh: this.titleCh,
            titleEn: this.titleEn,
            imageData: this.imageData,
          },
          { headers: authHeader() }
        )
        .then(function(response) {
          console.log(response);
          window.location.reload();
        })
        .catch(function(error) {
          console.log(error);
        });
      this.closeModal();
    },
    fileChange(e) {
      this.image = e.target.files[0];
      const reader = new FileReader();
      reader.onloadend = (e) => {
        this.imageData = e.target.result;
      };
      reader.readAsDataURL(this.image);
    },
  },
};
</script>

<style scoped>
.modal {
  display: block;
}

.form-label {
  margin-top: 0.5rem;
}
</style>
