<template>
  <div id="group-member" class="px-4">
    <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
      <div class="col" :key="event._id" v-for="event in data">
        <Team :event="event" :eventId="event._id" />
      </div>
    </div>
  </div>
</template>
<script>
import Team from "./Team.vue";
export default {
  name: "Teams",
  props: {
    data: Object,
  },
  components: {
    Team,
  },
};
</script>
<style scoped></style>
