<template>
  <div id="mySidebar" class="sidebar">
    <router-link to="/admin" class="m-3 d-flex ms-4 p-1">
      <BootstrapIcon icon="columns" class="m-0 me-3" aria-label="Info" />Basic
      Info</router-link
    >
    <router-link to="/admin/topimage" class="m-3 d-flex ms-4 p-1"
      ><BootstrapIcon
        icon="card-image"
        class="m-0 me-3"
        aria-label="Image"
      />Top Image</router-link
    >
    <router-link to="/admin/listing" class="m-3 d-flex ms-4 p-1"
      ><BootstrapIcon
        icon="pencil-square"
        class="m-0 me-3"
        aria-label="Listing"
      />Listing</router-link
    >
    <router-link to="/admin/event" class="m-3 d-flex ms-4 p-1"
      ><BootstrapIcon
        icon="calendar-event"
        class="m-0 me-3"
        aria-label="Event"
      />Event</router-link
    >
    <router-link to="/admin/join" class="m-3 d-flex ms-4 p-1"
      ><BootstrapIcon
        icon="person-plus"
        class="m-0 me-3"
        aria-label="Join"
      />Join</router-link
    >
    <router-link to="/admin/advertise" class="m-3 d-flex ms-4 p-1"
      ><BootstrapIcon
        icon="badge-ad"
        class="m-0 me-3"
        aria-label="Adv"
      />Advertise</router-link
    >
    <router-link to="/admin/email" class="m-3 d-flex ms-4 p-1"
      ><BootstrapIcon
        icon="envelope"
        class="m-0 me-3"
        aria-label="Email"
      />Message</router-link
    >

    <router-link to="/admin/password" class="m-3 d-flex ms-4 p-1"
      ><BootstrapIcon
        icon="key"
        class="m-0 me-3"
        aria-label="Password"
      />Password</router-link
    >
  </div>

  <div id="main">
    <div class="bg-dark text-white pt-2 pb-1">
      <div
        class="
          d-flex
          flex-wrap
          align-items-center
          justify-content-center justify-content-lg-start
        "
      >
        <a href="#" class="nav-link text-white" @click="toggle(flag)">
          <BootstrapIcon icon="card-list" class="d-block m-0" />
        </a>
        <div class="navbar-brand text-white">Admin Portal</div>

        <a href="#" class="nav-link text-white ms-auto" @click.prevent="logOut">
          <BootstrapIcon icon="box-arrow-right" class="d-block m-0" />
        </a>
      </div>
    </div>
    <router-view />
  </div>
</template>
<script>
export default {
  name: "Admin",
  data() {
    return { flag: false };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }
  },
  methods: {
    toggle(flag) {
      if (!flag) {
        document.getElementById("mySidebar").style.width = "250px";
        document.getElementById("main").style.marginLeft = "250px";
        this.flag = true;
      } else {
        document.getElementById("mySidebar").style.width = "0";
        document.getElementById("main").style.marginLeft = "0";
        this.flag = false;
      }
    },
    logOut() {
      localStorage.removeItem("userEmail");
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
  },
};
</script>
<style scoped>
.bi {
  font-size: 2rem;
}
.top {
  margin-top: 20px;
}
/* The sidebar menu */
.sidebar {
  height: 100%; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  top: 0;
  left: 0;
  background-color: #343a40; /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 60px; /* Place content 60px from the top */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidebar */
}

.sidebar a {
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

/* When you mouse over the navigation links, change their color */
.sidebar a:hover {
  color: #f1f1f1;
}

/* Position and style the close button (top right corner) */
.sidebar .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

/* The button used to open the sidebar */
.openbtn {
  font-size: 20px;
  cursor: pointer;
  background-color: #111;
  color: white;
  padding: 10px 15px;
  border: none;
}

.openbtn:hover {
  background-color: #444;
}

#main {
  transition: margin-left 0.5s;
}
#main .bi {
  font-size: 2.5rem;
}

@media screen and (max-height: 450px) {
  .sidebar {
    padding-top: 15px;
  }
  .sidebar a {
    font-size: 18px;
  }
}
</style>
