<template>
  <td>
    <img
      :src="$webDomain + '/' + event.mainImageType + '/' + event.mainImage"
      alt="not found"
    />
  </td>
  <td>{{ event.eventTitleEn }}</td>
  <td>{{ event.eventTitleCh }}</td>
  <td>{{ event.eventContentEn }}</td>
  <td>{{ event.eventContentCh }}</td>
  <td>{{ event.link }}</td>
  <td>
    <BootstrapIcon
      class="listTableIcon"
      icon="pencil-square"
      @click="showEdit()"
    />
    <BootstrapIcon
      class="listTableIcon delete"
      icon="trash"
      @click="deleteItem(event._id)"
    />
  </td>
  <ModalEventEdit
    :event="event"
    v-if="displayEdit"
    @close-modal-event="hideEdit"
  ></ModalEventEdit>
</template>
<script>
import axios from "axios";
import authHeader from "../../services/authHeader";
import ModalEventEdit from "./ModalEventEdit.vue";

export default {
  name: "AdminEventTable",
  props: {
    event: Object,
  },
  components: {
    ModalEventEdit,
  },
  data() {
    return {
      displayEdit: false,
    };
  },
  methods: {
    deleteItem(id) {
      axios
        .delete(this.$webDomain + "/estate/EstateEvent", {
          headers: authHeader(),
          data: {
            itemId: id,
          },
        })
        .then(function(response) {
          console.log(response);
          window.location.reload();
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    showEdit() {
      this.displayEdit = true;
    },
    hideEdit() {
      this.displayEdit = false;
    },
  },
};
</script>
<style scoped>
img {
  max-width: 200px;
}
</style>
