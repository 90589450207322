<template>
  <!-- Chat Button -->
  <div class="chatButton">
    <a class="fixedButton" @click="toggleChatForm">
      <!-- Opening Btn -->
      <div class="roundedFixedBtn" v-if="!popup">
        {{ $t("homePage.chatBtn.chat") }}
      </div>
      <!-- Close Btn -->
      <div class="roundedCloseBtn" v-else>
        <i class="far fa-times-circle"></i>
      </div>
    </a>

    <!-- Input Form -->
    <div class="chat-popup-form" id="myForm" v-if="popup">
      <Form
        class="popup-form-container"
        @submit="onSubmit"
        :validation-schema="schema"
        @invalid-submit="onInvalidSubmit"
      >
        <!-- Form Title -->
        <h2 class="my-1">{{ $t("homePage.chatBtn.form.title") }}</h2>
        <!-- Bottom Line -->
        <div class="bottom-line"></div>

        <!-- Full Name Input -->
        <div class="popup-form-input">
          <label for="name">{{ $t("homePage.chatBtn.form.name") }}</label>
          <Field
            type="text"
            name="name"
            :placeholder="$t('homePage.chatBtn.form.namePlaceHolder')"
            v-model="NameOfSender"
          />
          <ErrorMessage class="errorMessage" name="name" />
        </div>

        <!-- Email Input -->
        <div class="popup-form-input">
          <label for="email">{{ $t("homePage.chatBtn.form.email") }}</label>
          <Field
            type="email"
            name="email"
            :placeholder="$t('homePage.chatBtn.form.emailPlaceHolder')"
            v-model.trim="emailOfSender"
          />
          <ErrorMessage class="errorMessage" name="email" />
        </div>

        <!-- Phone Number Input -->
        <div class="popup-form-input">
          <label for="phone">{{ $t("homePage.chatBtn.form.phone") }}</label>
          <Field
            type="text"
            name="phone"
            :placeholder="$t('homePage.chatBtn.form.phonePlaceHolder')"
            v-model="phoneOfSender"
          />
          <ErrorMessage class="errorMessage" name="phone" />
        </div>

        <!-- Message Input -->
        <div class="popup-form-message text-start">
          <!-- <label for="msg" class="my-1">{{
            $t("homePage.chatBtn.form.message")
          }}</label> -->
          <Field
            as="textarea"
            :placeholder="$t('homePage.chatBtn.form.messagePlaceHolder')"
            name="msg"
            v-model="messageContent"
            required
          />
          <ErrorMessage class="errorMessage mb-2" name="msg" />
        </div>

        <!-- Submit Btn -->
        <button type="submit" class="btn mt-2 fs-5">
          {{ $t("homePage.chatBtn.form.send") }}
        </button>
      </Form>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as Yup from "yup";
import authHeader from "../services/authHeader";
import axios from "axios";

export default {
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      popup: false,
      phoneOfSender: "",
      NameOfSender: "",
      emailOfSender: "",
      messageContent: "",
      obj: {},
    };
  },
  methods: {
    // Validator function
    onSubmit(values) {
      // alert(JSON.stringify(values, null, 2));
      this.popup = false;
      axios
        .post(
          this.$webDomain + "/estateInitNew/EstateMessage",
          {
            phone: this.phoneOfSender,
            name: this.NameOfSender,
            email: this.emailOfSender,
            messageContent: this.messageContent,
          },
          { headers: authHeader() }
        )
        .then(function(response) {
          console.log(response);
          alert("The message is sent succesfully!");
          // window.location.reload();
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    isRequired(value) {
      return value ? true : "This field is required";
    },
    toggleChatForm() {
      this.popup = !this.popup;
    },
  },
  setup() {
    function onInvalidSubmit() {
      const submitBtn = document.querySelector(".submit-btn");
      submitBtn.classList.add("invalid");
      setTimeout(() => {
        submitBtn.classList.remove("invalid");
      }, 1000);
    }

    // Using yup to generate a validation schema
    // https://vee-validate.logaretm.com/v4/guide/validation#validation-schemas-with-yup
    const schema = Yup.object().shape({
      name: Yup.string().required(),
      email: Yup.string()
        .email()
        .required(),
      phone: Yup.string()
        .min(10)
        .required(),
      msg: Yup.string().required(),
    });

    return {
      schema,
      onInvalidSubmit,
    };
  },
};
</script>

<style scoped>
.chatButton {
  line-height: 1;
}

/* Button used to open the chat form - fixed at the bottom of the page */
/* .fixedButton {
  position: fixed;
  bottom: 0px;
  right: 0px;
  padding: 0 20px 30px 0;
  text-decoration: none;
  z-index: 9999;
} */
/* .roundedFixedBtn {
  height: 60px;
  line-height: 60px;
  width: 60px;
  font-size: 18px;
  font-weight: 500;
  border-radius: 50%;
  background-color: #cb011b;
  color: white;
  text-align: center;
  cursor: pointer;
}

.roundedCloseBtn {
  font-size: 60px;
  color: #cb011b;
} */

.roundedCloseBtn i {
  position: fixed;
  bottom: 0px;
  right: 0px;
  padding: 0 20px 30px 0;
  text-decoration: none;
}

/* The popup chat - hidden by default */
.chat-popup-form {
  position: fixed;
  bottom: 100px;
  right: 15px;
  border: 3px solid #f1f1f1;
  z-index: 999;
}

/* Add styles to the form container */
.popup-form-container {
  max-width: 300px;
  padding: 10px;
  background-color: white;
}

.popup-form-input {
  text-align: left;
  margin: 10px 0;
  padding: 0.1rem;
}

.popup-form-message {
  padding: 0.1rem;
}

.popup-form-container label {
  font-weight: bold;
  margin-right: 10px;
  width: 50px;
  text-align: center;
}

.popup-form-container input {
  border: none;
  background: #ddd;
  padding: 5px 5px 5px 9px;
}

/* Full-width textarea */
.popup-form-container textarea {
  width: 100%;
  padding: 15px;
  border: none;
  margin: 5px 0 5px 0;
  background: #ddd;
  resize: none;
  min-height: 120px;
}

/* When the textarea gets focus, do something */
.popup-form-container textarea:focus {
  background-color: #ddd;
  outline: none;
}

/* Set a style for the submit/login button */
.popup-form-container .btn {
  background-color: #04aa6d;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-bottom: 10px;
  opacity: 0.8;
}

/* Add some hover effects to buttons */
.popup-form-container .btn:hover,
.open-button:hover {
  opacity: 1;
}

.errorMessage {
  color: red;
}

/* For Iphone 6/7/8 */
@media (max-width: 413px) {
  .fixedButton {
    padding: 0 15px 15px 0;
  }

  .roundedCloseBtn i {
    padding: 0 15px 15px 0;
  }

  /* The popup chat - hidden by default */
  .chat-popup-form {
    width: 100%;
    bottom: 80px;
    right: 0;
    border: 2px solid #f1f1f1;
  }

  /* Add styles to the form container */
  .popup-form-container {
    max-width: 100%;
  }

  .popup-form-container label {
    display: none;
  }

  .popup-form-container input {
    width: 100%;
  }

  .popup-form-input {
    margin: 15px 0;
  }

  .popup-form-container textarea {
    margin: 0;
  }

  /* Set a style for the submit/login button */
  .popup-form-container .btn {
    margin-bottom: 0;
  }
}
</style>
