<template>
  <div class="modal" tabindex="-1">
    <div
      class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable"
    >
      <div class="modal-content">
        <div class="modal-header bg-light">
          <h5 class="modal-title fw-bold">Listing Details Edit</h5>
          <button type="button" class="btn-close" @click="closeModal"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <!-- General Information Card -->
            <div class="inner-container col-md-4 p-3">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">General Information</h5>
                  <hr />
                  <!-- MLS -->
                  <div class="form-floating mb-3">
                    <input
                      type="text"
                      class="form-control"
                      aria-label="MLS"
                      v-model="mls"
                      @change="update('mlsNumber', mls)"
                    />
                    <label>MLS® No</label>
                  </div>
                  <!-- Price -->
                  <div class="form-floating mb-3">
                    <input
                      type="text"
                      class="form-control"
                      aria-label="Amount"
                      v-model="price"
                      @change="update('price', price)"
                    />
                    <label>Price</label>
                  </div>
                </div>
              </div>
              <!-- Location Card -->
              <div class="card mt-3">
                <div class="card-body">
                  <h5 class="card-title">Location</h5>
                  <hr />
                  <!-- Intersection -->
                  <div class="form-floating mb-3">
                    <input
                      type="text"
                      class="form-control"
                      aria-label="intersection"
                      v-model="intersection"
                      @change="update('intersection', intersection)"
                    />
                    <label>Intersection</label>
                  </div>
                  <!-- Community -->
                  <div class="form-floating mb-3">
                    <input
                      type="text"
                      class="form-control"
                      aria-label="community"
                      placeholder="community"
                      v-model="community"
                      @change="update('community', community)"
                    />
                    <label>Community</label>
                  </div>
                  <!-- Unit -->
                  <div class="form-floating mb-3">
                    <input
                      type="text"
                      class="form-control"
                      aria-label="unit"
                      v-model="unit"
                      @change="update('unit', unit)"
                    />
                    <label>Unit</label>
                  </div>
                  <!-- Street -->
                  <div class="form-floating mb-3">
                    <input
                      type="text"
                      class="form-control"
                      aria-label="street"
                      v-model="street"
                      @change="update('street', street)"
                    />
                    <label for="street">Street</label>
                  </div>
                  <!-- City -->
                  <div class="form-floating mb-3">
                    <input
                      type="text"
                      class="form-control"
                      aria-label="city"
                      v-model="city"
                      @change="update('city', city)"
                    />
                    <label>City</label>
                  </div>
                  <!-- Province -->
                  <div class="form-floating mb-3">
                    <input
                      type="text"
                      class="form-control"
                      aria-label="province"
                      v-model="province"
                      @change="update('province', province)"
                    />
                    <label>Province</label>
                  </div>
                  <!-- Country -->
                  <div class="form-floating mb-3">
                    <input
                      type="text"
                      class="form-control"
                      aria-label="country"
                      v-model="country"
                      @change="update('country', country)"
                    />
                    <label>Country</label>
                  </div>
                  <!-- Post Code -->
                  <div class="form-floating mb-3">
                    <input
                      type="text"
                      class="form-control"
                      aria-label="postcode"
                      v-model="postcode"
                      @change="update('postcode', postcode)"
                    />
                    <label>Post Code</label>
                  </div>
                </div>
              </div>
            </div>

            <!-- Property Description Card -->
            <div class="inner-container col-md-4 py-3 px-1">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Property Information</h5>
                  <hr />
                  <!-- dimension -->
                  <!-- Width -->
                  <div class="form-floating mb-3">
                    <input
                      type="text"
                      class="form-control"
                      aria-label="landwidth"
                      v-model="width"
                      @change="update('landWidth', width)"
                    />
                    <label>Width</label>
                  </div>
                  <!-- Height -->
                  <div class="form-floating mb-3">
                    <input
                      type="text"
                      class="form-control"
                      aria-label="landheight"
                      v-model="height"
                      @change="update('landHeight', height)"
                    />
                    <label>Height</label>
                  </div>

                  <!-- Property Type -->
                  <div class="form-floating mb-3">
                    <select
                      class="form-select"
                      v-model="propertyType"
                      @change="update('propertyType', propertyType)"
                    >
                      <option disabled value="">property type</option>
                      <option value="1">Detached</option>
                      <option value="2">Semi-Detached</option>
                      <option value="3">Att/Row/Twnhouse</option>
                      <option value="4">Condo Apt</option>
                    </select>
                    <label>Property Type</label>
                  </div>
                  <!-- Property Style -->
                  <div class="form-floating mb-3">
                    <select
                      class="form-select"
                      v-model="propertyStyle"
                      @change="update('propertyStyle', propertyStyle)"
                    >
                      <option disabled value="">property style</option>
                      <option value="1">2-Storey</option>
                      <option value="2">3-Storey</option>
                      <option value="3">Apartment</option>
                      <option value="4">Bungalow</option>
                    </select>
                    <label>Property Style</label>
                  </div>
                  <!-- Exterior Finish -->
                  <div class="form-floating mb-3">
                    <select
                      class="form-select"
                      v-model="exterior"
                      @change="update('exteriorStyle', exterior)"
                    >
                      <option disabled value="">exterior finish</option>
                      <option value="1">Stone</option>
                      <option value="2">Brick</option>
                      <option value="3">Fiber Cement</option>
                      <option value="4">Wood</option>
                    </select>
                    <label>Exterior Finish</label>
                  </div>
                  <!-- Basement -->
                  <div class="form-floating mb-3">
                    <select
                      class="form-select"
                      v-model="basement"
                      @change="update('basementStyle', basement)"
                    >
                      <option disabled value="">basement style</option>
                      <option value="1">Finished</option>
                      <option value="2">Unfinished</option>
                      <option value="3">None</option>
                    </select>
                    <label>Basement</label>
                  </div>

                  <!-- Rooms -->
                  <div class="row">
                    <!-- Bedroom -->
                    <div class="col">
                      <div class="form-floating mb-3">
                        <input
                          type="text"
                          class="form-control"
                          aria-label="numberOfBedrooms"
                          v-model="bedroom"
                          @change="update('bedroomsNumber1', bedroom)"
                        />
                        <label>Bedroom</label>
                      </div>
                    </div>
                    <!-- Bathroom -->
                    <div class="col">
                      <div class="form-floating mb-3">
                        <input
                          type="text"
                          class="form-control"
                          aria-label="numberOfBathrooms"
                          v-model="bathroom"
                          @change="update('bathroomNumber', bathroom)"
                        />
                        <label>Bathroom</label>
                      </div>
                    </div>
                  </div>
                  <!-- Kitchen & Parking -->
                  <div class="row">
                    <!-- Kitchen -->
                    <div class="col">
                      <div class=" form-floating mb-3">
                        <input
                          type="text"
                          class="form-control"
                          aria-label="numberOfKitchens"
                          v-model="kitchen"
                          @change="update('kitchenNumber', kitchen)"
                        />
                        <label>Kitchen</label>
                      </div>
                    </div>
                    <!-- Parking -->
                    <div class="col">
                      <div class=" form-floating mb-3">
                        <input
                          type="text"
                          class="form-control"
                          aria-label="numberOfParkings"
                          v-model="parking"
                          @change="update('parkingNumber', parking)"
                        />
                        <label>Parking</label>
                      </div>
                    </div>
                  </div>
                  <!-- Garage -->

                  <!-- Garage -->
                  <div class=" form-floating mb-3">
                    <input
                      type="text"
                      class="form-control"
                      aria-label="numberOfGarages"
                      v-model="garage"
                      @change="update('garageNumber', garage)"
                    />
                    <label>Garage</label>
                  </div>
                  <!-- Garage Type -->
                  <div class=" form-floating mb-3">
                    <select
                      class="form-select"
                      v-model="garageType"
                      @change="update('garageType', garageType)"
                    >
                      <option disabled value="">garage type</option>
                      <option value="1">Built-In</option>
                      <option value="2">Attached</option>
                      <option value="3">Undergrnd</option>
                    </select>
                    <label>Garage Type</label>
                  </div>

                  <!-- Central AC -->
                  <div class="col form-floating mb-3">
                    <select
                      class="form-select"
                      v-model="centralAC"
                      @change="update('centralAC', centralAC)"
                    >
                      <option disabled value="">Central AC</option>
                      <option value="1">N</option>
                      <option value="2">Y</option>
                    </select>
                    <label>Central AC</label>
                  </div>

                  <!-- Heat -->
                  <div class="row">
                    <!-- Heat Source -->
                    <div class="col">
                      <div class=" form-floating mb-3">
                        <select
                          class="form-select"
                          v-model="heatSource"
                          @change="update('heatSource', heatSource)"
                        >
                          <option disabled value="">Heat Source</option>
                          <option value="1">Gas</option>
                          <option value="2">Oil</option>
                          <option value="3">Water</option>
                          <option value="4">Electrical</option>
                        </select>
                        <label>Heat Source</label>
                      </div>
                    </div>
                    <!-- Heat Type -->
                    <div class="col">
                      <div class=" form-floating mb-3">
                        <select
                          class="form-select"
                          v-model="heatType"
                          @change="update('heatType', heatType)"
                        >
                          <option disabled value="">Heat Type</option>
                          <option value="1">Forced Air</option>
                          <option value="2">Heat Pump</option>
                          <option value="3">Boiler</option>
                        </select>
                        <label>Heat Type</label>
                      </div>
                    </div>
                  </div>

                  <!-- Device -->
                  <div class="row">
                    <!-- Fire Place -->
                    <div class="col">
                      <div class=" form-floating mb-3">
                        <select
                          class="form-select"
                          v-model="firePlace"
                          @change="update('firePlace', firePlace)"
                        >
                          <option disabled value="">fireplace</option>
                          <option value="1">N</option>
                          <option value="2">Y</option>
                        </select>
                        <label>Fire Place</label>
                      </div>
                    </div>
                    <!-- Pool -->
                    <div class="col">
                      <div class=" form-floating mb-3">
                        <select
                          class="form-select"
                          v-model="pool"
                          @change="update('poolType', pool)"
                        >
                          <option disabled value="">pool type</option>

                          <option value="1">N</option>
                          <option value="2">Y</option>
                        </select>
                        <label>Pool</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Description Card -->
            <div class="inner-container col-md-4 p-3">
              <div class="card h-100">
                <div class="card-body">
                  <h5 class="card-title">Description</h5>
                  <hr />
                  <!-- In English -->
                  <h6>English</h6>
                  <div class="form-floating mb-4">
                    <textarea
                      class="form-control bg-light"
                      v-model="descriptionEn"
                      @change="update('descriptionEn', descriptionEn)"
                    ></textarea>
                    <label>Description in English</label>
                  </div>
                  <!-- In Chinese -->
                  <h6>Chinese</h6>
                  <div class="form-floating">
                    <textarea
                      class="form-control bg-light"
                      v-model="descriptionCh"
                      @change="update('descriptionCh', descriptionCh)"
                    ></textarea>
                    <label>Description in Chinese</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Room List Section -->
          <div class="row px-3">
            <!-- Room List Table -->
            <div class="inner-container p-3">
              <div class="card">
                <div class="card-body">
                  <h5 class="d-inline me-2 card-title">Room List</h5>
                  <BootstrapIcon
                    class="add-btn primary"
                    icon="plus-circle"
                    @click="showModalRoom"
                  />

                  <table
                    class="table table-hover table-bordered align-middle mt-3"
                  >
                    <caption>
                      List of rooms
                    </caption>
                    <thead>
                      <tr>
                        <th scope="col">Room Type</th>
                        <th scope="col">Width</th>
                        <th scope="col">Height</th>
                        <th scope="col">Location Type</th>
                        <th scope="col">Unit Type</th>
                        <th scope="col">Options</th>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- Room List -->
                      <tr :key="rooms._id" v-for="rooms in roomList">
                        <modal-listing-edit-room-table
                          v-if="roomList"
                          :rooms="rooms"
                          :listId="id"
                          @reopen-modal-room="reopenListingEdit"
                        />
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <!-- Listing Image Section -->
          <div class="inner-container p-3">
            <div class="card">
              <div class="card-body">
                <h5 class="d-inline me-2">Listing Image</h5>
                <BootstrapIcon
                  class="add-btn primary"
                  icon="plus-circle"
                  @click="showModalImage"
                />

                <table class="table table-hover align-middle">
                  <caption>
                    List of images
                  </caption>
                  <thead>
                    <tr>
                      <th scope="col">Preview</th>
                      <th scope="col">Options</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr :key="image._id" v-for="image in imageList">
                      <ModalListingEditImageTable
                        :image="image"
                        :listId="id"
                        @reopen-modal-room="reopenListingEdit"
                      />
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <!-- Preview Image Section -->
          <div class="inner-container px-3">
            <div class="card">
              <div class="card-body">
                <h5 class="d-inline me-2 card-title">Preview Image</h5>
                <BootstrapIcon
                  class="add-btn primary"
                  icon="plus-circle"
                  @click="showModalPreviewImage"
                />
                <table class="table table-hover mt-3">
                  <thead>
                    <tr>
                      <th scope="col">Uploaded Preview Image</th>
                    </tr>
                  </thead>
                  <tbody class="text-center">
                    <tr>
                      <img
                        :src="
                          $webDomain +
                            '/' +
                            infoMainImageType +
                            '/' +
                            infoMainImage
                        "
                        alt="not found"
                      />
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer bg-light">
          <button type="button" class="btn btn-primary" @click="saveChanges">
            Save Changes
          </button>
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
            @click="closeModal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
  <ModalListingEditImage
    v-if="displayModalImage"
    :id="id"
    @close-modal-image="hideModalImage"
    @reopen-modal-room="reopenListingEdit"
  />
  <ModalListingEditRoom
    v-if="displayModalRoom"
    :id="id"
    @close-modal-event="hideModalRoom"
    @reopen-modal-room="reopenListingEdit"
  />
  <ModalListingEditPreviewImg
    v-if="displayModalPreviewImg"
    :id="id"
    @close-modal-event="hideModalPreviewImage"
    @reopen-modal-room="reopenListingEdit"
  />
</template>

<script>
import ModalListingEditImage from "./ModalListingEditImage.vue";
import ModalListingEditImageTable from "./ModalListingEditImageTable.vue";
import ModalListingEditRoom from "./ModalListingEditRoom.vue";
import ModalListingEditRoomTable from "./ModalListingEditRoomTable.vue";
import ModalListingEditPreviewImg from "./ModalListingEditPreviewImg.vue";
import axios from "axios";
import authHeader from "../../services/authHeader";
export default {
  name: "ModalListingEdit",
  components: {
    ModalListingEditImage,
    ModalListingEditImageTable,
    ModalListingEditRoom,
    ModalListingEditRoomTable,
    ModalListingEditPreviewImg,
  },
  props: ["info"],
  emits: ["close-modal-event", "reopen-listing-edit"],
  data() {
    return {
      displayModalImage: false,
      displayModalRoom: false,
      displayModalPreviewImg: false,

      mls: this.info.mlsNumber,
      price: this.info.price,
      intersection: this.info.intersection,
      community: this.info.community,
      unit: this.info.unit,
      street: this.info.street,
      city: this.info.city,
      province: this.info.province,
      country: this.info.country,
      postcode: this.info.postcode,
      propertyType: this.info.propertyType,
      propertyStyle: this.info.propertyStyle,
      width: this.info.landWidth,
      height: this.info.landHeight,
      basement: this.info.basementStyle,
      exterior: this.info.exteriorStyle,
      bedroom: this.info.bedroomsNumber1,
      bathroom: this.info.bathroomNumber,
      kitchen: this.info.kitchenNumber,
      garageType: this.info.garageType,
      garage: this.info.garageNumber,
      parking: this.info.parkingNumber,
      centralAC: this.info.centralAC,
      heatSource: this.info.heatSource,
      heatType: this.info.heatType,
      firePlace: this.info.firePlace,
      pool: this.info.poolType,
      descriptionEn: this.info.descriptionEn,
      descriptionCh: this.info.descriptionCh,
      obj: {},

      infoMainImageType: this.info.mainImageType,
      infoMainImage: this.info.mainImage,
      id: this.info._id,
      roomList: null,
      imageList: null,
    };
  },
  mounted() {
    // Axios get RoomList and ImageList from server
    axios
      .get(this.$webDomain + "/estateInit/EstateListing/" + this.info._id)
      .then((response) => {
        this.roomList = response.data.data.roomList;
        this.imageList = response.data.data.listingImageList;
      });
  },
  methods: {
    closeModal() {
      this.$emit("close-modal-event");
    },
    saveChanges() {
      this.obj["itemId"] = this.info._id;
      axios
        .put(this.$webDomain + "/estate/EstateListing", this.obj, {
          headers: authHeader(),
        })
        .then((response) => {
          console.log(response);
          window.location.reload();
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // Show & Hide Modal Image
    showModalImage() {
      this.displayModalImage = true;
    },
    hideModalImage() {
      this.displayModalImage = false;
    },
    // Show & Hide Modal Room
    showModalRoom() {
      this.displayModalRoom = true;
    },
    hideModalRoom() {
      this.displayModalRoom = false;
    },
    // Show & Hide Modal Preview Image
    showModalPreviewImage() {
      this.displayModalPreviewImg = true;
    },
    hideModalPreviewImage() {
      this.displayModalPreviewImg = false;
    },
    // Emit Reopen Modal Listing Edit Request
    // To achieve reload data without refresh the whole page
    reopenListingEdit() {
      this.$emit("reopen-listing-edit");
    },

    update(key, value) {
      if (
        key === "mlsNumber" ||
        key === "intersection" ||
        key === "community" ||
        key === "unit" ||
        key === "street" ||
        key === "city" ||
        key === "province" ||
        key === "country" ||
        key === "postcode" ||
        key === "descriptionEn" ||
        key === "descriptionCh"
      ) {
        this.obj[key] = value;
      } else {
        this.obj[key] = parseFloat(value);
      }
    },
  },
};
</script>

<style scoped>
.modal {
  display: block;
}

.modal-fullscreen {
  width: 98vw;
}

.addBtn {
  width: 150px;
  margin-bottom: 20px;
}

.saveBtn {
  width: 150px;
  margin: 30px auto 10px auto;
}

img {
  width: 200px;
  height: 200px;
}

.tableTitle {
  margin-bottom: 20px;
}

textarea {
  height: 260px !important;
}
</style>
