<template>
  <td>
    <div>{{ createdDate }}</div>
    <div>{{ createdTime }}</div>
    <!-- <div>{{ message._id }}</div> -->
  </td>
  <td>{{ message.email }}</td>
  <td>{{ message.name }}</td>
  <td>{{ message.phone }}</td>
  <td>
    <div class="message-container">
      {{ message.messageContent }}
    </div>
  </td>
  <td>
    <BootstrapIcon
      class="listTableIcon"
      data-bs-toggle="modal"
      :data-bs-target="'#' + 'a' + message._id"
      icon="envelope-open"
    />
    <BootstrapIcon
      class="listTableIcon  delete"
      icon="trash"
      @click="deleteItem(message._id)"
    />
  </td>

  <!-- Message Modal -->
  <ModalEmailContent :message="message" :receiveTime="receiveTime" />
</template>

<script>
import axios from "axios";
import authHeader from "../../services/authHeader";
import ModalEmailContent from "./ModalEmailContent.vue";

export default {
  name: "AdminEmailTable",
  props: ["message"],
  components: {
    ModalEmailContent,
  },
  data() {
    return {
      displayEdit: false,
      displayMessage: false,
      createdDate: this.message.createdDate.substring(0, 10),
      createdTime: this.message.createdDate.substring(11, 19),
    };
  },
  computed: {
    receiveTime() {
      const receiveTime = this.createdDate + " /" + " " + this.createdTime;
      return receiveTime;
    },
  },
  methods: {
    // 当前未完成后台删除功能，无法删除
    deleteItem(id) {
      axios
        .put(
          this.$webDomain + "/estateNew/EstateMessageDelete",
          {
            itemId: id,
          },
          {
            headers: authHeader(),
          }
        )
        .then(function(response) {
          console.log(response);
          window.location.reload();
        })
        .catch(function(error) {
          console.log(error);
        });
    },
  },
};
</script>
