<template>
  <img
    :src="$webDomain + '/' + event.mainImageType + '/' + event.mainImage"
    alt="not found"
    class="img-thumbnail"
    data-bs-toggle="modal"
    :data-bs-target="'#' + 'a' + eventId + 'Modal'"
  />

  <!-- Modal -->
  <div
    class="modal fade"
    :id="'a' + eventId + 'Modal'"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5
            class="modal-title"
            :id="eventId + 'ModalLabel'"
            v-show="$i18n.locale === 'EN'"
          >
            {{ event.eventTitleEn }}
          </h5>
          <h5
            class="modal-title"
            :id="eventId + 'ModalLabel'"
            v-show="$i18n.locale === 'CH'"
          >
            {{ event.eventTitleCh }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <img
            :src="
              $webDomain + '/' + event.mainImageType + '/' + event.mainImage
            "
            alt="not found"
            class="img-thumbnail"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
          />

          <div class="card-body">
            <div class="card-text">
              <span v-show="$i18n.locale === 'EN'">{{
                event.eventContentEn
              }}</span>
              <span v-show="$i18n.locale === 'CH'">{{
                event.eventContentCh
              }}</span>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <!-- <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button> -->
          <button type="button" class="btn btn-danger mx-auto">
            <a :href="'//' + event.link" class="text-white" target="blank"
              >Link</a
            >
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Team",
  props: {
    event: Object,
    eventId: String,
  },
};
</script>

<style scoped>
img {
  height: 300px;
  width: 100%;
  cursor: pointer;
}
</style>
