<template>
  <div class="about">
    <Header />
    <!-- <AboutHeader /> -->

    <Basics :data="info" />
    <AboutCarousel v-if="images != ''" :images="images" />
    <div class="bg-light1">
      <Feature :data="info" />
    </div>
    <Rooms v-if="roomList != ''" :roomList="roomList" />
    <ChatButton />

    <Footer />
  </div>
</template>
<script>
import Basics from "../components/About/Basics.vue";
import AboutCarousel from "../components/About/AboutCarousel.vue";
import Feature from "../components/About/Feature.vue";
import Rooms from "../components/About/Rooms.vue";
// import AboutHeader from "../components/About/AboutHeader.vue";
import Header from "../components/Home/Header.vue";
import Footer from "../components/Footer.vue";
import ChatButton from "../components/ChatButton.vue";
import { useRoute } from "vue-router";
import { computed } from "vue";
import axios from "axios";
export default {
  components: {
    Basics,
    AboutCarousel,
    Feature,
    Rooms,
    // AboutHeader,
    Header,
    Footer,
    ChatButton,
  },
  data() {
    return { info: null, roomList: null, images: null };
  },
  mounted() {
    axios
      .get(this.$webDomain + "/estateInit/EstateListing/" + this.listingId)
      .then((response) => {
        this.info = response.data.data;
        this.roomList = response.data.data.roomList;
        this.images = response.data.data.listingImageList;
      });
  },
  setup() {
    const route = useRoute();
    const listingId = computed(() => route.params.id);
    return {
      listingId,
    };
  },
};
</script>
<style scoped>
h1 {
  color: crimson;
  margin-top: 200px;
  font: 1em sans-serif;
}

.about {
  line-height: 2.2rem;
}
</style>
